import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@edxp-auth/services/auth.service';
import { passwordContentValidator, passwordMatchValidator } from '@edxp-auth/components/signup/signup.validators';
import { AuthErrorsLogin, AuthErrorsSignup } from '@edxp-auth/utils/constants/auth-errors';
import { UserService } from '@edxp-core/api/services/user.service';
import { FunctionReturn } from '@edxp-core/api/utils/functions.utils';
import { toDisplayName } from '@edxp-core/api/utils/names.utils';
import { DASHBOARD_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE } from '@edxp-core/utils/constants/routes.constants';
// import { years } from '@edxp-pages/profile/components/personal-info/personal-info.component'; TODO: uncomment when using years
import { CountriesService, CountryOption } from '@edxp-shared/custom-inputs/services/countries.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { PixelService } from 'ngx-pixel';
import { UserSessionService } from '@edxp-core/api/services/user-session.service';
import { FirebaseAuthError } from '@edxp-core/api/utils/firebase.utils';

@Component({
  selector: 'edxp-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public signupForm: FormGroup = new FormGroup({});

  public errorMsg: string = '';
  public showPasswordNoMatchError: boolean = false;
  public loading: boolean = false;
  public googleLoading: boolean = false;

  public readonly countriesList$: Observable<CountryOption[]> = this.countriesService.countriesList$;
  public readonly displaySuccessfulSignUpMsg$: Observable<boolean> = this.authService.displaySuccessfulSignUpMsg$;

  // public years = years; TODO: uncomment when using years

  public siteKey = environment.siteKey;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private countriesService: CountriesService,
    private titleService: Title,
    private translate: TranslateService,
    private pixel: PixelService,
    private userSessionService: UserSessionService
  ) {}

  @HostListener('window:keyup.enter') public onEnterKeyup() {
    this.signUp();
  }

  public ngOnInit(): void {
    this.translate
      .get('PAGES.SIGNUP')
      .pipe(take(1))
      .subscribe((title) => {
        this.titleService.setTitle(title);
      });

    this.signupForm = this.fb.group({
      firstName: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      lastName: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6), passwordContentValidator])],
      confirmPassword: ['', Validators.compose([Validators.required])],
      // year: ['', Validators.compose([Validators.required])], TODO: uncomment when using years
      // country: ['', Validators.compose([Validators.required])],
      recaptcha: [null, Validators.compose([Validators.required])]
    });
    this.signupForm.setValidators(passwordMatchValidator);
  }

  public signUp(): void {
    if (this.signupForm.errors?.passwordNoMatch) {
      this.showPasswordNoMatchError = true;
    }
    if (this.signupForm.valid) {
      this.loading = true;
      this.errorMsg = '';
      const firstName = this.signupForm?.controls.firstName.value ?? '';
      const lastName = this.signupForm?.controls.lastName.value ?? '';
      // const year = this.signupForm?.controls.year.value ?? ''; TODO: uncomment when using years
      // const country = this.signupForm?.controls.country.value ?? '';
      const country = '';
      const recaptchaResponse = this.signupForm?.controls.recaptcha.value ?? '';
      this.authService
        .emailSignup({
          email: this.signupForm.controls.email.value,
          password: this.signupForm.controls.password.value,
          displayName: toDisplayName(firstName, lastName),
          // year, TODO: uncomment when using years
          country,
          response: recaptchaResponse
        })
        .subscribe((res: FunctionReturn) => {
          if (res.success) {
            this.authService.login(this.signupForm.controls.email.value, this.signupForm.controls.password.value).then(() => {
              this.userService.sendVerificationEmail();
              this.finishCreatingAccount();

              // pixel log
              this.pixel.track('CompleteRegistration');
            });
          } else {
            const error = res.error;
            this.signupForm?.controls.recaptcha.reset();
            switch (error?.code) {
              case AuthErrorsSignup.WEAK_PASSWORD: {
                this.errorMsg = 'AUTHENTICATION.ERRORS.WEAK-PASSWORD';
                break;
              }
              case AuthErrorsSignup.EMAIL_EXISTS: {
                this.errorMsg = 'AUTHENTICATION.ERRORS.EMAIL-EXISTS';
                break;
              }
              case AuthErrorsSignup.INVALID_RECAPTCHA: {
                this.errorMsg = 'AUTHENTICATION.ERRORS.INVALID-RECAPTCHA';
                break;
              }
              default: {
                this.errorMsg = 'AUTHENTICATION.ERRORS.UNKNOWN';
                break;
              }
            }
          }
          this.loading = false;
        });
    } else {
      this.signupForm.markAllAsTouched();
    }
  }

  public finishCreatingAccount = (): void => {
    this.authService.updateDisplayMsgSubject(true);
    this.authService.logout(true);
    // this.router.navigate([SIGNUP_ROUTE]);
  };

  public goToLogin(): void {
    this.errorMsg = '';
    this.router.navigate([LOGIN_ROUTE]);
  }

  public googleSignIn(): void {
    this.errorMsg = '';
    this.authService
      .googleLogin()
      .then((r) => {
        this.googleLoading = true;
        this.userSessionService.reloadUser();
        this.userSessionService.user$
          .pipe(takeWhile((user) => !user || (!user.unlockedSubjects && !user.level), true))
          .subscribe((user) => {
            if (user?.unlockedSubjects || user?.level) {
              this.googleLoading = false;
              this.router.navigate([DASHBOARD_ROUTE]);
            } else {
              setTimeout(() => this.userSessionService.reloadUser(), 1000);
            }
          });
      })
      .catch((err: FirebaseAuthError) => {
        switch (err.code) {
          case AuthErrorsLogin.USER_DISABLED: {
            this.errorMsg = 'AUTHENTICATION.ERRORS.ACCOUNT-DISABLED';
            break;
          }
          default: {
            this.errorMsg = 'AUTHENTICATION.ERRORS.UNKNOWN';
            break;
          }
        }
      });
  }
}
