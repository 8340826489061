import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SUPPORTED_FILE_TYPES } from '@edxp-core/utils/constants/file-extension';

@Component({
  selector: 'edxp-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {
  @ViewChild('fileInput') private fileInput: ElementRef<HTMLInputElement> | undefined = undefined;

  @Output() public documentSelected: EventEmitter<File> = new EventEmitter();

  public readonly supportedFileTypes = SUPPORTED_FILE_TYPES.join(', ');

  constructor() {}

  public ngOnInit(): void {}

  public changeHandler(event: Event): void {
    if (this.fileInput) {
      const eventTarget = event.target as HTMLInputElement;
      const file = eventTarget.files ? eventTarget.files[0] : undefined;
      if (file) this.documentSelected.next(file);
    }
  }

  public triggerUpload(): void {
    this.fileInput?.nativeElement.click();
  }
}
