import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { MobileDetectorService } from '@edxp-core/services/mobile-detector.service';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class CustomDialogService {
  constructor(private dialog: MatDialog, private mobileDetectorService: MobileDetectorService) {}

  public openDialog<T>(component: ComponentType<T>, config: MatDialogConfig = {}): MatDialogRef<T> {
    let dialogConfig: MatDialogConfig = {
      width: '480px'
    };
    if (this.mobileDetectorService.isSmallScreen) {
      dialogConfig = {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw'
      };
    }

    return this.dialog.open(component, { ...dialogConfig, ...config });
  }
}
