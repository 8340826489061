import { FunctionsErrorHandlingService } from './functions-error-handling.service';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  constructor(private angularFunctionsService: AngularFireFunctions, private functionsErrorService: FunctionsErrorHandlingService) {}

  public handleHttpCallableFunction<T>(functionName: string, data: any = {}): Observable<T> {
    const callable = this.angularFunctionsService.httpsCallable(functionName);

    return callable(data).pipe(
      tap((res: any) => {
        if (res?.error) this.functionsErrorService.openErrorSnackbar(res.error);
      })
    );
  }
}
