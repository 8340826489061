import { Component, OnInit } from '@angular/core';
import { MobileDetectorService } from '@edxp-core/services/mobile-detector.service';
import { HubspotService } from '@edxp-core/utils/hubspot/hubspot.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'edxp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isDesktop$: Observable<boolean> = this.detectorService.isDesktop$;
  public isAllowedPage = window.location.href.indexOf('verify-email') >= 0 || window.location.href.indexOf('verifyEmail') >= 0;
  constructor(private detectorService: MobileDetectorService, private hubspotService: HubspotService) {}

  public ngOnInit(): void {
    // this.isAllowedPage = window.location.href.indexOf('verify-email') >= 0 || window.location.href.indexOf('verifyEmail') >= 0;
    console.log(this.isAllowedPage);
  }

  public goBack(): void {
    window.history.back();
  }
}
