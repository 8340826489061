import { MaterialModule } from './material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SafeHtmlPipe } from '@edxp-core/pipes/safe-html.pipe';
import { DarkModeDirective } from './directives/dark-mode.directive';
import { GalleryModule } from 'ng-gallery';
import { TextResizeDirective } from './directives/text-resize.directive';
import { KatexPipe } from './pipes/katex.pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'progress', loadingText: 'Loading...' }),
    GalleryModule
  ],
  exports: [
    MaterialModule,
    TranslateModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxSkeletonLoaderModule,
    SafeHtmlPipe,
    DarkModeDirective,
    GalleryModule,
    TextResizeDirective,
    KatexPipe
  ],
  declarations: [SafeHtmlPipe, DarkModeDirective, TextResizeDirective, KatexPipe]
})
export class CoreModule {}
