<form [formGroup]="loginForm"
  (keyup.enter)="signIn()">
  <mat-form-field appearance="outline">
    <mat-label for="login-email">{{ 'AUTHENTICATION.SHARED.EMAIL' | translate }}<span class="text-danger">*</span>
    </mat-label>
    <input matInput
      type="email"
      autocomplete="email"
      id="login-email"
      formControlName="email">
    <mat-error *ngIf="loginForm.hasError('required', 'email')">
      {{ 'AUTHENTICATION.ERRORS.EMAIL.REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="loginForm.hasError('email', 'email') && !loginForm.hasError('required', 'email')">
      {{ 'AUTHENTICATION.ERRORS.EMAIL.INVALID' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline"
    class="centered-suffix-text">
    <mat-label for="login-password">{{ 'AUTHENTICATION.SHARED.PASSWORD' | translate }}<span class="text-danger">*</span>
    </mat-label>
    <input #loginInput
      matInput
      type="password"
      autocomplete="password"
      id="login-password"
      formControlName="password">
    <mat-error *ngIf="loginForm.hasError('required', 'password')">
      {{ 'AUTHENTICATION.ERRORS.PASSWORD.REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  <div class="forgot-password-container">
    <a class="forgot-password"
      (click)="goToForgotPass()">{{ 'AUTHENTICATION.LOGIN.FORGOT-PASSWORD' | translate }}</a>
  </div>
  <div class="error-container"
    *ngIf="errorMsg">
    {{ errorMsg | translate }}
  </div>
  <div class="error-container"
    *ngIf="emailNotVerified">
    <p>
      {{ 'AUTHENTICATION.ERRORS.NOT-VERIFIED' | translate }}
    </p>
  </div>
</form>
<div class="form-actions-container">
  <button mat-raised-button
    color="primary"
    (click)="signIn()"
    [disabled]="loading || googleLoading">
    <ng-container *ngIf="!loading; else loadingTpl">{{ 'AUTHENTICATION.SHARED.LOGIN-BTN' | translate }}</ng-container>
    <ng-template #loadingTpl><img src="assets/images/ball-triangle-primary.svg">
    </ng-template>
  </button>
  <span class="or-message">{{ 'AUTHENTICATION.LOGIN.OR' | translate }}</span>
  <button mat-flat-button
    color="secondary"
    class="google-button"
    [disabled]="googleLoading || loading"
    (click)="googleSignIn()">
    <span *ngIf="!googleLoading"
      class="google-icon"></span>
    <ng-container *ngIf="!googleLoading; else loadingTplGoogle">{{ 'AUTHENTICATION.LOGIN.GOOGLE' | translate }}</ng-container>
    <ng-template #loadingTplGoogle><img src="assets/images/ball-triangle-primary.svg">
    </ng-template>
  </button>
  <span class="sign-up">
    {{ 'AUTHENTICATION.LOGIN.NO-ACCOUNT' | translate }}
    <span class="sign-up-link"
      (click)="goToSignup()">{{ 'AUTHENTICATION.SHARED.SIGNUP-BTN' | translate }}</span>
  </span>
</div>
