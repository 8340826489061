import { FORGOT_PASS_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE } from '@edxp-core/utils/constants/routes.constants';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

interface NavLink {
  label: string;
  link: string;
  index: number;
}
@Component({
  selector: 'edxp-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  public navLinks: NavLink[] = [];
  public activeLinkIndex: number = -1;
  public isLoginOrSignupRoute: boolean = true;

  constructor(private router: Router) {
    this.navLinks = [
      {
        label: 'AUTHENTICATION.TAB-LABELS.LOGIN',
        link: `${LOGIN_ROUTE}`,
        index: 0
      },
      {
        label: 'AUTHENTICATION.TAB-LABELS.SIGNUP',
        link: `${SIGNUP_ROUTE}`,
        index: 1
      }
    ];
  }

  public ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.isLoginOrSignupRoute = this.router.url.includes(LOGIN_ROUTE) || this.router.url.includes(SIGNUP_ROUTE);
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find((tab) => tab.link === this.router.url) || this.navLinks[0]);
    });
  }
}
