import { userLevels } from '@edxp-core/experience-system/constants/user-levels.constants';
import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { ShortUser, User, UserNotification } from '@edxp-models/user.model';
import { FirebaseService } from '@edxp-core/api/services/firebase.service';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FirebaseApp } from '@angular/fire';
import { FirebaseFirestoreDocumentReference, FirebaseUser } from '@edxp-core/api/utils/firebase.utils';
import { FunctionsService } from '@edxp-core/api/services/functions.service';
import { UserFunctions } from '@edxp-core/api/utils/functions.utils';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService extends FirebaseService {
  private shortCurrentUserSubject: BehaviorSubject<ShortUser | null> = new BehaviorSubject<ShortUser | null>(null);
  private userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private reloadSubject: BehaviorSubject<null> = new BehaviorSubject<null>(null);
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private userFirestoreDocRef: FirebaseFirestoreDocumentReference | undefined;

  public shortCurrentUser$: Observable<ShortUser | null> = this.shortCurrentUserSubject.asObservable();
  public user$: Observable<User | null> = this.userSubject.asObservable();
  public authState$: Observable<FirebaseUser | null> = this.afAuth.authState.pipe(takeUntil(this.destroy$));
  public reload$: Observable<null> = this.reloadSubject.asObservable();
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  public get shortCurrentUserSnapshot(): ShortUser | null {
    return this.shortCurrentUserSubject.value;
  }

  constructor(@Inject(FirebaseApp) firebaseApp: FirebaseApp, private afAuth: AngularFireAuth, private functionsService: FunctionsService) {
    super(firebaseApp);
    // TODO SEE IF THIS CAN BE DONE BETTER
    combineLatest([this.authState$, this.reload$])
      .pipe(
        tap(([user]) => {
          this.updateUser();
        }),
        switchMap(([user]) => {
          if (user) {
            this.loadingSubject.next(true);

            return this.functionsService.handleHttpCallableFunction(UserFunctions.GET_USER_INFO).pipe(
              map((userData: any) => {
                this.loadingSubject.next(false);
                if (userData?.uid) {
                  return {
                    displayName: userData.displayName ?? '',
                    photoURL: userData.photoURL ?? '',
                    uid: userData.uid,
                    email: userData.email,
                    emailVerified: userData.emailVerified,
                    // year: userData.year ?? '', // TODO: uncomment when using years
                    country: userData.country ?? '',
                    skillScoresTrees: userData.skillScoresTrees ?? [],
                    unlockedSubjects: userData.unlockedSubjects ?? [],
                    notifications: userData.notifications ?? [],
                    freeTrialAvailable: userData.freeTrialAvailable ?? false,
                    level: userData.level
                      ? { ...userData.level, progress: (userData.level.xpGained / userData.level.maxXp) * 100 }
                      : userLevels[0]
                  } as User;
                } else {
                  return this.shortCurrentUserSubject.value;
                }
              })
            );
          }

          return of(null);
        })
      )
      .subscribe((userState) => {
        if (userState) this.userSubject.next({ ...userState } as User);
        else this.userSubject.next(null);
      });
  }

  public updateUser(): void {
    const user = this.firebaseApp.auth().currentUser;
    if (!user) this.shortCurrentUserSubject.next(null);
    else {
      this.shortCurrentUserSubject.next({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified
      });
    }
  }

  public reloadUser(): void {
    this.reloadSubject.next(null);
  }
}
