import { SubmitFailedDialogComponent } from './../components/submit-failed-dialog/submit-failed-dialog.component';
import { InitialAssessmentFunctions, AssessmentFunctions } from '@edxp-core/api/utils/functions.utils';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Injectable } from '@angular/core';
import { FunctionsService } from '@edxp-core/api/services/functions.service';
import { generateSubjectBackgroundClass } from '@edxp-core/utils/constants/background.constants';
import { Assessment, AssessmentInitializer, AssessmentQuestion, SolvedAssessment } from '@edxp-models/assessment.model';
import { AnsweredQuestion } from '@edxp-models/question.model';
import { SkillNode } from '@edxp-models/skill.model';
import { CardCarouselService } from '@edxp-shared/question-cards-layout/services/card-carousel.service';
import { BehaviorSubject, combineLatest, interval, Observable, timer } from 'rxjs';
import { debounceTime, map, pairwise, shareReplay, switchMap, take } from 'rxjs/operators';
import { ASSESSMENTS_KEY, COMPLETED_KEY, ONGOING_KEY, DARK_MODE_KEY } from '../utils/constants/storage.constants';
import { CustomDialogService } from '@edxp-core/services/custom-dialog.service';
import { HasFinishedDialogComponent } from '@edxp-pages/assessment/components/has-finished-dialog/has-finished-dialog.component';
import { Router } from '@angular/router';
import { ASSESSMENT_ROUTE, ASSESSMENT_SUMMARY_ROUTE } from '@edxp-core/utils/constants/routes.constants';
import { SkillTreeService } from '@edxp-skills/services/skill-tree.service';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {
  private assessmentSubject: BehaviorSubject<Assessment | undefined> = new BehaviorSubject<Assessment | undefined>(undefined);
  private ongoingAssessmentSubject: BehaviorSubject<SolvedAssessment | undefined> = new BehaviorSubject<SolvedAssessment | undefined>(
    undefined
  );
  private completedAssessmentSubject: BehaviorSubject<SolvedAssessment | undefined> = new BehaviorSubject<SolvedAssessment | undefined>(
    undefined
  );
  private seenQuestionsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private currentSkillNodeSubject: BehaviorSubject<SkillNode | undefined> = new BehaviorSubject<SkillNode | undefined>(undefined);
  private currentQuestionIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private xpGainedSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private isDarkModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isSidenavPreviewModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private isHoveringQuestionListBtnSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public currentQuestionId$: Observable<string> = this.currentQuestionIdSubject.asObservable();
  public seenQuestions$: Observable<string[]> = this.seenQuestionsSubject.asObservable();
  public currentSkillNode$: Observable<SkillNode | undefined> = this.currentSkillNodeSubject.asObservable();
  public xpGained$: Observable<number> = this.xpGainedSubject.asObservable();
  public isHoveringQuestionListBtn$: Observable<boolean> = this.isHoveringQuestionListBtnSubject.pipe(
    switchMap((val) => timer(0, 500).pipe(map(() => val)))
  );
  public shouldShowSidenavPreview$: Observable<boolean> = this.isHoveringQuestionListBtn$.pipe(
    pairwise(),
    map(([prev, next]: [boolean, boolean]) => prev && next)
  );
  public isSidenavPreviewMode$: Observable<boolean> = this.isSidenavPreviewModeSubject.asObservable();

  constructor(
    private functionsService: FunctionsService,
    private cardCarouselService: CardCarouselService,
    private dialogService: CustomDialogService,
    private skillTreeService: SkillTreeService,
    private router: Router,
    private analytics: AngularFireAnalytics
  ) {}

  private getAssessmentKindObject<T>(id: string, toCheckSubject: BehaviorSubject<T | undefined>, typeKey: string): T | undefined {
    if (toCheckSubject.value) return toCheckSubject.getValue();

    const localStorageItem = localStorage.getItem(typeKey);
    if (!localStorageItem) return undefined;

    const parsedObject = JSON.parse(localStorageItem)[id];
    toCheckSubject.next(parsedObject);

    return parsedObject;
  }

  private addOrUpdateAssessmentKindObject<T>(id: string, value: T, toCheckSubject: BehaviorSubject<T | undefined>, typeKey: string): void {
    const localStorageItemsList = localStorage.getItem(typeKey);
    if (!localStorageItemsList) {
      const newObject = {};
      newObject[id] = value;
      localStorage.setItem(typeKey, JSON.stringify(newObject));
    } else {
      const object = JSON.parse(localStorageItemsList);
      if (object) {
        object[id] = value;
        localStorage.setItem(typeKey, JSON.stringify(object));
      }
    }
    toCheckSubject.next(value);
  }

  private removeAssessmentKindLocalStorageEntry(id: string, typeKey: string): void {
    // get assessment item
    const localStorageCollection = localStorage.getItem(typeKey);
    if (localStorageCollection) {
      const parsedCollection = JSON.parse(localStorageCollection);
      delete parsedCollection[id];
      // set the updated value back to localstorage
      localStorage.setItem(typeKey, JSON.stringify(parsedCollection));
    }
  }

  private addOrUpdateAnswer(question: AnsweredQuestion, assessment: SolvedAssessment): AnsweredQuestion[] {
    const assessmentQuestions = assessment.questions;
    const alreadyAddedQuestion = assessmentQuestions.find((q) => q.id === question.id);
    if (alreadyAddedQuestion) {
      assessmentQuestions[assessmentQuestions.indexOf(alreadyAddedQuestion)] = question;
    } else {
      assessmentQuestions.push(question);
    }

    return assessmentQuestions;
  }

  private markQuestionAsSeen(questionId: string): void {
    const alreadySeenQuestion = this.seenQuestionsSubject.value.find((id) => id === questionId);
    if (!alreadySeenQuestion) {
      this.seenQuestionsSubject.next(this.seenQuestionsSubject.value.concat([questionId]));
    }
  }

  private addOrUpdateCompletedAssessment(assessmentValue: SolvedAssessment): void {
    this.addOrUpdateAssessmentKindObject<SolvedAssessment>(
      assessmentValue.id,
      assessmentValue,
      this.completedAssessmentSubject,
      COMPLETED_KEY
    );
  }

  private removeFromOngoingAssessments(assessmentId: string): void {
    this.removeAssessmentKindLocalStorageEntry(assessmentId, ONGOING_KEY);
    //TODO PUSH UNDEFINED TO SUBJECT. SEE IF THIS IS NEEDED AND OK
    this.ongoingAssessmentSubject.next(undefined);
  }

  private getAssessment(assessmentId: string): Assessment | undefined {
    return this.getAssessmentKindObject<Assessment>(assessmentId, this.assessmentSubject, ASSESSMENTS_KEY);
  }

  private getOngoingAssessment(assessmentId: string): SolvedAssessment | undefined {
    return this.getAssessmentKindObject<SolvedAssessment>(assessmentId, this.ongoingAssessmentSubject, ONGOING_KEY);
  }

  private getCompletedAssessment(assessmentId: string): SolvedAssessment | undefined {
    return this.getAssessmentKindObject<SolvedAssessment>(assessmentId, this.completedAssessmentSubject, COMPLETED_KEY);
  }

  private checkIfAllQuestionsAnswered(assessmentId: string, prevQuestionsLength: number): void {
    const assessment = this.assessmentSubject.value;
    const ongoingAssessment = this.getOngoingAssessment(assessmentId);
    if (assessment && ongoingAssessment?.questions.length !== prevQuestionsLength) {
      if (assessment.questions.length === ongoingAssessment?.questions.length) {
        this.dialogService
          .openDialog(HasFinishedDialogComponent, { autoFocus: false })
          .afterClosed()
          .subscribe((res) => {
            if (res) this.router.navigate([ASSESSMENT_ROUTE, assessment.id, ASSESSMENT_SUMMARY_ROUTE]);
          });
      }
    }
  }
  // STARTED ASSESSMENTS
  public getAssessment$(assessmentId: string): Observable<Assessment | undefined> {
    // Update Subject first
    this.getAssessment(assessmentId);

    return this.assessmentSubject.asObservable();
  }

  public addOrUpdateAssessment(assessmentValue: Assessment): void {
    this.addOrUpdateAssessmentKindObject<Assessment>(assessmentValue.id, assessmentValue, this.assessmentSubject, ASSESSMENTS_KEY);
  }

  // ONGOING ASSESSMENTS
  public getOngoingAssessment$(assessmentId: string): Observable<SolvedAssessment | undefined> {
    // Update subject first
    this.getOngoingAssessment(assessmentId);

    return this.ongoingAssessmentSubject.asObservable();
  }

  public addOrUpdateOngoingAssessment(assessmentValue: SolvedAssessment): void {
    this.addOrUpdateAssessmentKindObject<SolvedAssessment>(assessmentValue.id, assessmentValue, this.ongoingAssessmentSubject, ONGOING_KEY);
  }

  // COMPLETED ASSESSMENTS
  public getCompletedAssessment$(assessmentId: string): Observable<SolvedAssessment | undefined> {
    // Update subject first
    this.getCompletedAssessment(assessmentId);

    return this.completedAssessmentSubject.asObservable();
  }

  public setCurrentQuestionId(id: string): void {
    this.currentQuestionIdSubject.next(id);
    this.markQuestionAsSeen(id);
    const questionIndex = this.assessmentSubject.value?.questions?.findIndex((q) => q.id === id) || 0;
    this.cardCarouselService.setSpecificQuestion(questionIndex);
  }

  public goToNextQuestion(): void {
    const currentQuestion = this.assessmentSubject.value?.questions?.find((q) => q.id === this.currentQuestionIdSubject.value);
    if (currentQuestion) {
      this.setCurrentQuestionId(
        this.assessmentSubject.value?.questions[this.assessmentSubject.value.questions.indexOf(currentQuestion) + 1].id as string
      );
    }
  }

  public goToPrevQuestion(): void {
    const currentQuestion = this.assessmentSubject.value?.questions?.find((q) => q.id === this.currentQuestionIdSubject.value);
    if (currentQuestion) {
      this.setCurrentQuestionId(
        this.assessmentSubject.value?.questions[this.assessmentSubject.value.questions.indexOf(currentQuestion) - 1].id as string
      );
    }
  }

  public setCardCarouselQuestions(questions: AssessmentQuestion[]): void {
    const mappedQuestions = questions.map((question) => {
      const questionToReturn = question;

      if (question.hasParent) {
        const questionParent = questions.find((q) => q.id === question.parentId);
        const otherPreviousSiblings = questions.filter(
          (q) => q.parentId === question.parentId && (q.orderNumber || 0) < (question.orderNumber || 0)
        );
        if (questionParent) questionToReturn.relatedQuestions = [questionParent, ...otherPreviousSiblings];
      }

      return questionToReturn;
    });
    this.cardCarouselService.setQuestions(mappedQuestions);
    if (!this.currentQuestionIdSubject.value) {
      this.setCurrentQuestionId(questions[0].id);
    }
  }

  public setSkillNodeValue(skillNode: SkillNode | undefined): void {
    this.currentSkillNodeSubject.next(skillNode);
  }

  public answerQuestion(answeredQuestion: AnsweredQuestion, assessmentId: string): void {
    const ongoingAssessment = this.getOngoingAssessment(assessmentId);
    if (ongoingAssessment) {
      const prevQuestionsLength = ongoingAssessment.questions.length;
      this.addOrUpdateOngoingAssessment({
        ...ongoingAssessment,
        questions: this.addOrUpdateAnswer(answeredQuestion, ongoingAssessment)
      });
      if (this.assessmentSubject.value && answeredQuestion.indexNumber !== this.assessmentSubject.value.questions.length - 1) {
        this.checkIfAllQuestionsAnswered(assessmentId, prevQuestionsLength);
      }
    }
  }

  public submitAssessment(assessmentId: string): Observable<any> {
    const startedAssessment = this.getAssessment(assessmentId);
    const solvedOngoingAssessment = this.getOngoingAssessment(assessmentId);
    // find all unanswered questions, and set userAnswer to unanswered
    const notAnsweredQuestions = startedAssessment?.questions
      .filter((q) => !solvedOngoingAssessment?.questions.find((aq) => aq.id === q.id))
      .map((notAnsweredQuestion: AssessmentQuestion) => ({
        id: notAnsweredQuestion.id,
        text: notAnsweredQuestion.text,
        indexNumber: notAnsweredQuestion.indexNumber,
        userAnswer: {
          text: 'NOT_ANSWERED',
          explanation: 'NOT_ANSWERED'
        },
        solution: notAnsweredQuestion.solution
      }));
    // weird eslint behavior here
    let completelySolvedAssessment: SolvedAssessment | undefined = solvedOngoingAssessment;
    // populate ongoing assessment solution with the unanswered questions
    if (solvedOngoingAssessment) {
      completelySolvedAssessment = {
        ...solvedOngoingAssessment,
        questions: solvedOngoingAssessment?.questions.concat(notAnsweredQuestions || []) || []
      };

      // save new version of ongoing assessment
      this.addOrUpdateOngoingAssessment(completelySolvedAssessment);
    }

    this.currentQuestionIdSubject.next('');

    // this will also return the solvedAssessment
    this.analytics.logEvent('submit_assessment', { assessmentId });

    return this.functionsService
      .handleHttpCallableFunction<SolvedAssessment>(
        completelySolvedAssessment?.isInitialAssessment ? InitialAssessmentFunctions.SUBMIT : AssessmentFunctions.SUBMIT,
        {
          id: completelySolvedAssessment?.id,
          skillId: completelySolvedAssessment?.skillId,
          questions: completelySolvedAssessment?.questions.map((q) => ({
            id: q.id,
            userAnswer: q.userAnswer,
            indexNumber: q.indexNumber
          }))
        }
      )
      .pipe(take(1), shareReplay({ bufferSize: 1, refCount: false }));
  }

  public clearLocalStorageValues(): void {
    // localStorage.removeItem('completedAssessments');
    localStorage.removeItem('assessments');
    localStorage.removeItem('ongoingAssessments');
  }

  public updateXpGained(xpGained: number): void {
    this.xpGainedSubject.next(xpGained);
  }

  public moveFromOngoingToCompleted(completedAssessment: SolvedAssessment): void {
    // save assessment in completedAssessments
    this.addOrUpdateCompletedAssessment(completedAssessment);
    // and remove it from ongoingAssessments
    this.removeFromOngoingAssessments(completedAssessment.id);
  }

  public startAssessment(data: AssessmentInitializer): Observable<Assessment> {
    this.analytics.logEvent('assessment_started', { assessment: data });
    this.seenQuestionsSubject.next([]);

    return this.functionsService.handleHttpCallableFunction<Assessment>(AssessmentFunctions.GENERATE, data);
  }

  public startInitialAssessment(data: AssessmentInitializer): Observable<Assessment> {
    this.analytics.logEvent('initial_assessment_started', { assessment: data });
    this.seenQuestionsSubject.next([]);

    return this.functionsService.handleHttpCallableFunction<Assessment>(InitialAssessmentFunctions.GENERATE, data);
  }

  public dismissInitialAssessment(subjectId: string): Observable<any> {
    this.analytics.logEvent('initial_assessment_dismissed', { subject: subjectId });

    return this.functionsService.handleHttpCallableFunction<any>(InitialAssessmentFunctions.DISMISS, { subjectId });
  }

  public getDarkMode$(): Observable<boolean> {
    const darkModeItem = localStorage.getItem(DARK_MODE_KEY);
    if (darkModeItem) {
      const parsedDarkModeItem = JSON.parse(darkModeItem) as boolean;
      if (parsedDarkModeItem !== this.isDarkModeSubject.value) {
        this.isDarkModeSubject.next(parsedDarkModeItem);
      }
    }

    return this.isDarkModeSubject.asObservable();
  }

  public setDarkMode(value: boolean): void {
    localStorage.setItem(DARK_MODE_KEY, JSON.stringify(value));
    this.isDarkModeSubject.next(value);
  }

  public getBackgroundFromAssessment(assessment: SolvedAssessment | Assessment | undefined): string {
    return assessment
      ? generateSubjectBackgroundClass(this.skillTreeService.getSkillTreeFromSessionStorage(assessment.skillId)?.displayedName ?? '')
      : '';
  }

  public openSubmitAssessmentFailedDialog(): void {
    this.dialogService
      .openDialog(SubmitFailedDialogComponent, {
        autoFocus: false,
        disableClose: true
      })
      .afterClosed()
      .subscribe((result) => {
        localStorage.clear();
        this.router.navigate(['/']);
      });
  }

  public initFirstQuestionIfEmpty(questionId: string): void {
    if (this.currentQuestionIdSubject.value === '') {
      this.currentQuestionIdSubject.next(questionId);
    }
  }

  public changeButtonHovering(isHovering: boolean): void {
    this.isHoveringQuestionListBtnSubject.next(isHovering);
  }

  public changeIsSidenavPreviewMode(value: boolean): void {
    this.isSidenavPreviewModeSubject.next(value);
  }
}
