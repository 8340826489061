<h2 class="forgot-pass-title">{{ 'AUTHENTICATION.FORGOT-PASSWORD.TITLE' | translate }}</h2>
<p class="explanation-text">{{ 'AUTHENTICATION.FORGOT-PASSWORD.EXPLANATION' | translate }}</p>
<form [formGroup]="forgotPassForm">
  <mat-form-field appearance="outline">
    <mat-label for="forgot-pass-email">{{ 'AUTHENTICATION.SHARED.EMAIL' | translate }}<span class="text-danger">*</span>
    </mat-label>
    <input matInput
      type="email"
      autocomplete="email"
      id="forgot-pass-email"
      formControlName="email">
    <mat-error *ngIf="forgotPassForm.hasError('required', 'email')">
      {{ 'AUTHENTICATION.ERRORS.EMAIL.REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="forgotPassForm.hasError('email', 'email') && !forgotPassForm.hasError('required', 'email')">
      {{ 'AUTHENTICATION.ERRORS.EMAIL.INVALID' | translate }}
    </mat-error>
  </mat-form-field>
  <div class="error-container"
    *ngIf="errorMsg">
    {{ errorMsg | translate }}
  </div>
  <div class="success-container"
    *ngIf="resetPassSuccess">
    <p>{{ 'AUTHENTICATION.FORGOT-PASSWORD.SUCCESS' | translate }}</p>
  </div>
</form>
<div class="form-actions-container">
  <button mat-raised-button
    color="primary"
    (click)="sendResetLink()">
    {{ 'AUTHENTICATION.FORGOT-PASSWORD.BUTTON' | translate }}
  </button>
  <div class="text-button">
    <span (click)="goToLogin()">{{ 'AUTHENTICATION.FORGOT-PASSWORD.BACK-TO-LOGIN' | translate }}</span>
  </div>
</div>
