import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RESET_PASS_ROUTE, ROOT_ROUTE, VERIFY_EMAIL_ROUTE } from '@edxp-core/utils/constants/routes.constants';
import { ActionModes } from '@edxp-auth/utils/constants/action-operations';

@Injectable({
  providedIn: 'root'
})
export class ActionRedirectGuard implements CanActivate {
  constructor(private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Redirects to correct route based on action mode
    // Firebase allows only one link for all operations so we need to redirect to the correct component
    const oobCode = route.queryParamMap.get('oobCode') ?? '';
    const mode = route.queryParamMap.get('mode') ?? '';
    if (!oobCode || !mode) return this.router.parseUrl(ROOT_ROUTE);
    switch (mode) {
      case ActionModes.PASSWORD_RESET: {
        return this.router.parseUrl(`${ROOT_ROUTE}${RESET_PASS_ROUTE}?oobCode=${oobCode}`);
        break;
      }
      case ActionModes.VERIFY_EMAIL: {
        return this.router.parseUrl(`${ROOT_ROUTE}${VERIFY_EMAIL_ROUTE}?oobCode=${oobCode}`);
        break;
      }
      default: {
        return this.router.parseUrl(`${ROOT_ROUTE}`);
        break;
      }
    }
  }
}
