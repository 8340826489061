import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordContentValidator, passwordMatchValidator } from '@edxp-auth/components/signup/signup.validators';
import { ActivatedRoute, Router } from '@angular/router';
import { LOGIN_ROUTE } from '@edxp-core/utils/constants/routes.constants';
import { AuthService } from '@edxp-auth/services/auth.service';
import { AuthErrorsSignup } from '@edxp-auth/utils/constants/auth-errors';
import { FirebaseAuthError } from '@edxp-core/api/utils/firebase.utils';

@Component({
  selector: 'edxp-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  private oobCode: string = '';

  public resetPassForm: FormGroup = new FormGroup({});

  public showPasswordNoMatchError: boolean = false;

  public errorMsg: string = '';
  public success: boolean = false;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private authService: AuthService) {}

  public ngOnInit(): void {
    this.oobCode = this.route.snapshot.queryParamMap.get('oobCode') ?? '';
    this.resetPassForm = this.fb.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(6), passwordContentValidator])],
      confirmPassword: ['', Validators.compose([Validators.required])]
    });
    this.resetPassForm.setValidators(passwordMatchValidator);
  }

  public resetPass(): void {
    if (this.resetPassForm.errors?.passwordNoMatch) {
      this.showPasswordNoMatchError = true;
    }
    if (this.resetPassForm.valid) {
      this.errorMsg = '';
      this.authService
        .resetPassword(this.oobCode, this.resetPassForm.controls.password.value)
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.goToLogin();
          }, 2000);
        })
        .catch((err: FirebaseAuthError) => {
          switch (err.code) {
            case AuthErrorsSignup.WEAK_PASSWORD: {
              this.errorMsg = 'AUTHENTICATION.ERRORS.WEAK-PASSWORD';
              break;
            }
            default: {
              this.errorMsg = 'AUTHENTICATION.ERRORS.UNKNOWN';
              break;
            }
          }
        });
    }
  }

  public goToLogin(): void {
    this.router.navigate([LOGIN_ROUTE]);
  }
}
