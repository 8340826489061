export const getNameFromDisplay = (displayName: string): { firstName: string; lastName: string } => {
  const splitName = displayName.split(' ');
  if (splitName.length === 1)
    return {
      firstName: displayName,
      lastName: ''
    };
  const lastName = splitName[splitName.length - 1];
  const firstName = splitName.slice(0, -1).join(' ');

  return {
    firstName,
    lastName
  };
};

export const toDisplayName = (firstName: string, lastName: string): string => firstName.concat(' ', lastName);
