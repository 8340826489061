import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class FunctionsErrorHandlingService {
  constructor(private _snackBar: MatSnackBar) {}

  public openErrorSnackbar(error: any) {
    this._snackBar.open('Oops, something went wrong with your request.', 'Dismiss', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 5000,
      panelClass: 'snackbar-error'
    });
  }
}
