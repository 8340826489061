export enum Palettes {
  PRIMARY = 'primary-palette',
  RED = 'red-palette',
  BLUE = 'blue-palette',
  YELLOW = 'yellow-palette',
  GREEN = 'green-palette',
  BLACK = 'black-palette',
  GRAY = 'gray-palette'
}

export enum LightPalettes {
  PRIMARY = 'light-primary-palette',
  RED = 'light-red-palette',
  BLUE = 'light-blue-palette',
  YELLOW = 'light-yellow-palette',
  GREEN = 'light-green-palette',
  BLACK = 'light-black-palette'
}

// !IMPORTANT THESE NEED TO BE UPDATED ALONG WITH COLORS
// ! FROM _COLORS.SCSS AND _COLOR-VARIABLES.SCSS
export interface TypescriptPalette {
  color: string;
  mediumColor: string;
  lightColor: string;
  contrastColor?: string;
}

export const TypescriptRedPalette: TypescriptPalette = {
  color: '#E63946',
  mediumColor: '#FFB2B8',
  lightColor: '#FFF5F6'
};

export const TypescriptBluePalette: TypescriptPalette = {
  color: '#1FBCFF',
  mediumColor: '#E0F6FF',
  lightColor: '#F5FCFF'
};

export const TypescriptGreenPalette: TypescriptPalette = {
  color: '#2DC171',
  mediumColor: '#BAFFDA',
  lightColor: '#F5FFF9'
};

export const TypescriptYellowPalette: TypescriptPalette = {
  color: '#F7D02F',
  mediumColor: '#F7EDC3',
  lightColor: '#FFFDF5',
  contrastColor: '#252525'
};

export const TypescriptBlackPalette: TypescriptPalette = {
  color: '#252525',
  mediumColor: '#676767',
  lightColor: '#FFFFFF'
};

export const TypescriptGrayPalette: TypescriptPalette = {
  color: '#C4C4C4',
  mediumColor: '#F2F2F2',
  lightColor: '#F2F2F2',
  contrastColor: '#252525'
};

export const TypescriptPrimaryPalette: TypescriptPalette = {
  color: '#336699',
  mediumColor: '#D4E9FF',
  lightColor: '#F7FBFF',
  contrastColor: '#FFFFFF'
};

export function levelToPalettes(level: number): { palette: string; tsPalette: TypescriptPalette } {
  switch (level) {
    case 1:
      return { palette: Palettes.YELLOW, tsPalette: TypescriptYellowPalette };
    case 2:
      return { palette: Palettes.GREEN, tsPalette: TypescriptGreenPalette };
    case 3:
      return { palette: Palettes.BLUE, tsPalette: TypescriptBluePalette };
    case 4:
      return { palette: Palettes.RED, tsPalette: TypescriptRedPalette };
    case 5:
      return { palette: Palettes.BLACK, tsPalette: TypescriptBlackPalette };

    default:
      return { palette: Palettes.PRIMARY, tsPalette: TypescriptPrimaryPalette };
  }
}

export function levelToLightPalettes(level: number): { palette: string; tsPalette: TypescriptPalette } {
  switch (level) {
    case 1:
      return { palette: LightPalettes.YELLOW, tsPalette: TypescriptYellowPalette };
    case 2:
      return { palette: LightPalettes.GREEN, tsPalette: TypescriptGreenPalette };
    case 3:
      return { palette: LightPalettes.BLUE, tsPalette: TypescriptBluePalette };
    case 4:
      return { palette: LightPalettes.RED, tsPalette: TypescriptRedPalette };
    case 5:
      return { palette: LightPalettes.BLACK, tsPalette: TypescriptBlackPalette };

    default:
      return { palette: LightPalettes.PRIMARY, tsPalette: TypescriptPrimaryPalette };
  }
}
