import { CountriesService, CountryOption } from './../services/countries.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { switchMap, startWith, debounceTime, filter, map } from 'rxjs/operators';

@Component({
  selector: 'edxp-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit {
  @ViewChild('searchInput') public searchInput!: ElementRef<HTMLInputElement>;
  @Input() public countryControl!: FormControl;
  @Input() public required: boolean = false;

  public readonly countriesList$: Observable<CountryOption[]> = this.countriesService.countriesList$;
  public readonly searchControl: FormControl = new FormControl('');

  public readonly filtered$: Observable<CountryOption[]> = this.countriesList$.pipe(
    switchMap((countryOptions: CountryOption[]) =>
      this.searchControl.valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        filter((value: string) => typeof value === 'string'),
        map((text: string) =>
          countryOptions
            .filter((country: CountryOption) => country.name.toLowerCase().includes(text.toLowerCase()))
            .sort((a: CountryOption, b: CountryOption) => a.name.localeCompare(b.name))
        )
      )
    )
  );

  constructor(private countriesService: CountriesService) {}

  public ngOnInit(): void {}
}
