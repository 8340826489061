import { Inject } from '@angular/core';
import { FirebaseApp } from '@angular/fire';
import { SubscriptionCleanup } from '@edxp-core/utils/subscription-cleanup';
// eslint-disable-next-line import/no-duplicates
import 'firebase/firestore';
// eslint-disable-next-line import/no-duplicates
import 'firebase/storage';
import { collectionData } from 'rxfire/firestore';
import { Observable } from 'rxjs';
import {
  FirebaseFirestoreDocumentReference,
  FirebaseStorageRef,
  FirestoreDocumentData,
  flamelinkSchemaPrefix,
  usersPrefix
} from '../utils/firebase.utils';

export class FirebaseService extends SubscriptionCleanup {
  public firebaseApp: FirebaseApp;
  public collectionRef: FirestoreDocumentData;

  public storageRef: FirebaseStorageRef;

  constructor(@Inject(FirebaseApp) firebaseApp: FirebaseApp) {
    super();
    this.firebaseApp = firebaseApp;
    this.collectionRef = this.firebaseApp.firestore().collection('fl_content');
    const storage = this.firebaseApp.storage();
    // storage.useEmulator('localhost', 9199);
    this.storageRef = storage.ref();
  }

  public getFlamelinkSchemaData<T>(schemaName: string): Observable<T[]> {
    return collectionData(this.collectionRef.where(flamelinkSchemaPrefix, '==', schemaName));
  }

  public sendVerificationEmail(): Promise<void> | undefined {
    return this.firebaseApp.auth().currentUser?.sendEmailVerification();
  }

  public getUserDoc(uid: string | undefined): FirebaseFirestoreDocumentReference | undefined {
    if (!uid) return;

    return this.firebaseApp.firestore().doc(`${usersPrefix}/${uid}`);
  }
}
