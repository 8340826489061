export const environment = {
  production: true,
  // THIS IS DEVELOP CONFIG
  siteKey: '6Ld0uDocAAAAAJKsXwRGSk7hZrG0fZvhPjImK9si',
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyD7-uoUvuz3M8V6jOTua7kf5I3XA4B_Mi8',
    authDomain: 'edxp-8a8a8.firebaseapp.com',
    projectId: 'edxp-8a8a8',
    storageBucket: 'edxp-8a8a8.appspot.com',
    messagingSenderId: '914997857344',
    appId: '1:914997857344:web:3e9195313095b5a3ae23a5',
    measurementId: 'G-HZM8XWS63H'
  },
  // TODO CHANGE THE PRODUCTION KEY WITH THE LIVE KEY AFTER STRIPE ACCOUNT IS SETUP BY EDXP
  stripePk: 'pk_live_51JtRr9HJmxB96oNibgiQQIPbm6iYzBjCZ0WhTT2sKDseMxw72Y32NsfhMHBma6v5AuZFbDGTNQOsVQ5fCpkCgo7W00Sr1hKRuQ',
  pixelId: '1758706857816020'
};
