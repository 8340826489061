import { Inject, Injectable } from '@angular/core';
import { UserSessionService } from '@edxp-core/api/services/user-session.service';
import { Provider } from '@edxp-auth/utils/constants/providers';
import { FirebaseService } from '@edxp-core/api/services/firebase.service';
import {
  FirebaseAuthAuthCredential,
  FirebaseAuthUserCredential,
  FirebaseFirestoreDocumentData,
  FirebaseStorageUploadTaskSnapshot,
  usersPhotosPrefix,
  usersPrefix
} from '@edxp-core/api/utils/firebase.utils';
import { Observable } from 'rxjs';
import { percentage } from 'rxfire/storage';
import { FirebaseApp } from '@angular/fire';
import { FunctionReturn, UserFunctions } from '@edxp-core/api/utils/functions.utils';
import { FunctionsService } from '@edxp-core/api/services/functions.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService extends FirebaseService {
  constructor(
    @Inject(FirebaseApp) firebaseApp: FirebaseApp,
    private userSessionService: UserSessionService,
    private functionsService: FunctionsService
  ) {
    super(firebaseApp);
  }

  public changeUserProfile(profile: { displayName?: string | null; photoURL?: string | null }): Promise<void> | undefined {
    return new Promise((resolve, reject) => {
      this.firebaseApp
        .auth()
        .currentUser?.updateProfile(profile)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  public reauthenticateWithCredentials(credential: FirebaseAuthAuthCredential): Promise<FirebaseAuthUserCredential> | undefined {
    return this.firebaseApp.auth().currentUser?.reauthenticateWithCredential(credential);
  }

  public changeUserEmail(newEmail: string, credential: FirebaseAuthAuthCredential): Promise<void> | undefined {
    const reauthPromise = this.reauthenticateWithCredentials(credential);
    if (!reauthPromise) return undefined;

    return new Promise((resolve, reject) => {
      reauthPromise
        .then(() => {
          this.firebaseApp
            .auth()
            .currentUser?.updateEmail(newEmail)
            .then(() => {
              this.functionsService.handleHttpCallableFunction(UserFunctions.CHANGE_EMAIL).subscribe(
                (res) => {
                  if ((res as FunctionReturn).success) {
                    this.userSessionService.reloadUser();
                    resolve();
                  }
                },
                () => reject()
              );
            })
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  public updateUserInfo(data: FirebaseFirestoreDocumentData): Observable<any> {
    return this.functionsService
      .handleHttpCallableFunction(UserFunctions.UPDATE_USER_INFO, data)
      .pipe(tap(() => this.userSessionService.reloadUser()));
  }

  public getAuthProvider(): Provider[] | undefined {
    return this.firebaseApp.auth().currentUser?.providerData.map((userInfo) => userInfo?.providerId as Provider);
  }

  public changeUserPhoto(photo: File): Observable<{ progress: number; snapshot: FirebaseStorageUploadTaskSnapshot }> {
    const photoPathRef = this.storageRef.child(
      `${usersPrefix}/${usersPhotosPrefix}/${this.userSessionService.shortCurrentUserSnapshot?.uid}`
    );
    const task = photoPathRef.put(photo);

    return percentage(task);
  }
}
