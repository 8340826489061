export enum SkillsFunctions {
  GET_SKILL_BY_ID = 'getSkillById',
  GET_ALL_SKILLS = 'getAllSkills',
  GET_ALL_SUBJECTS = 'getAllSubjects',
  GET_SKILL_TREE = 'getSubjectSkillTree',
  GET_SKILLS_WITH_STATS = 'getSkillsWithStats',
  GET_TRY_NEW_SKILLS = 'getTryNewSkills',
  GET_SUBJECT_QUESTIONS_COUNT = 'getSubjectQuestionsCount'
}

export enum ExperienceFunctions {
  UPDATE_USER_SKILL_SCORES = 'updateUserSkillScores'
}

export enum UserFunctions {
  UPDATE_USER_INFO = 'updateUserInfo',
  GET_USER_INFO = 'getUserInfo',
  CHANGE_EMAIL = 'changeEmail',
  DISMISS_NOTIFICATIONS = 'dismissNotifications'
}

export enum AuthFunctions {
  CREATE_ACCOUNT = 'createAccount'
}

export enum AssessmentFunctions {
  GENERATE = 'generateAssessment',
  SUBMIT = 'submitAssessment'
}

export enum InitialAssessmentFunctions {
  GENERATE = 'generateInitialAssessment',
  SUBMIT = 'submitInitialAssessment',
  DISMISS = 'dismissInitialAssessment'
}

export enum CustomAssessmentFunctions {
  GET_CUSTOM_ASSESSMENTS = 'getCustomAssessments',
  GET_CUSTOM_ASSESSMENT_BY_ID = 'getCustomAssessmentById',
  GENERATE_CUSTOM_ASSESSMENT = 'generateCustomAssessment'
}

export enum TrainingFunctions {
  GET_TRAINING_QUESTION = 'getTrainingQuestions',
  SUBMIT_ANSWER = 'submitTrainingQuestion',
  SAVE_TRAINING_SESSION_REPORT = 'saveTrainingSessionReport'
}

export enum PurchasesFunctions {
  PURCHASE_SUBJECT = 'purchaseSubject',
  CREATE_STRIPE_CHECKOUT = 'createStripeCheckout',
  GET_SUBJECT_PRICES = 'getStripePricesBySubjectId',
  UNLOCK_FREE_TRIAL = 'unlockFreeTrial'
}

export enum ErrorFunctions {
  REPORT_ERROR = 'reportError'
}

export interface FunctionReturn {
  success: boolean;
  error?: {
    code: string;
    message: string;
  };
}
