import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edxp-page-under-maintenance',
  templateUrl: './page-under-maintenance.component.html',
  styleUrls: ['./page-under-maintenance.component.scss']
})
export class PageUnderMaintenanceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
