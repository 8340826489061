<div class="grid-container">
  <div class="visuals-container">
    <img src="/assets/images/banner-visual.png"
      class="background">
    <div class="texts-container">
      <div class="texts-wrapper">
        <div class="logo-wrapper">
          <div class="edxp-logo icon-logo-white"></div>
        </div>
        <p>{{ 'AUTHENTICATION.SIDEBAR.WELCOME-MESSAGE' | translate }}</p>
        <p>{{ 'AUTHENTICATION.SIDEBAR.TIP' | translate }}</p>
      </div>
      <div class="auth-footer">
        <span>{{ 'FOOTER.COPYRIGHT' | translate }}</span>
        <span>{{ 'FOOTER.TEXT' | translate }}&nbsp;<a target="_blank"
            href="https://www.atta.systems?utm_source=edxp&utm_medium=link&utm_campaign=client&utm_id=edxp">{{ 'FOOTER.ATTA' | translate }}</a>
        </span>
      </div>
    </div>
  </div>
  <div class="page-content-container">
    <div class="auth-tab-group-container">
      <nav mat-tab-nav-bar
        *ngIf="isLoginOrSignupRoute"
        mat-align-tabs="center">
        <a mat-tab-link
          *ngFor="let link of navLinks"
          [routerLink]="link.link"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
          [class.active-text]="rla.isActive">
          {{ link.label | translate }}
        </a>
      </nav>
      <div class="router-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
