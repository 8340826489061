export const ROOT_ROUTE = '/';
export const NOT_FOUND_ROUTE = `${ROOT_ROUTE}404`;

// AUTHENTICATION ROUTES
export const AUTH_ROUTE = 'authentication';
export const LOGIN_ROUTE = 'login';
export const SIGNUP_ROUTE = 'signup';
export const FORGOT_PASS_ROUTE = 'forgot-password';
export const ACTION_ROUTE = 'action';
export const RESET_PASS_ROUTE = 'reset-password';
export const VERIFY_EMAIL_ROUTE = 'verify-email';

// PAGES ROUTES
export const ASSESSMENT_ROUTE = 'assessment';
export const TRAINING_ROUTE = 'training';
export const DASHBOARD_ROUTE = 'dashboard';
export const SKILLS_ROUTE = 'skills';
export const PROFILE_ROUTE = 'profile';
export const SUBJECT_ROUTE = 'subject';

// PROFILE ROUTES
export const PERSONAL_INFO_ROUTE = 'personal-info';
export const BILLING_ROUTE = 'billing';
export const EMAIL_SETTINGS_ROUTE = 'email-settings';
export const PURCHASES_ROUTE = 'purchases';

// ASSESSMENT ROUTES
export const ASSESSMENT_SUMMARY_ROUTE = 'summary';
export const ASSESSMENT_RESULTS_ROUTE = 'results';
export const ASSESSMENT_ANSWERS_ROUTE = 'answers';

// TRAINING ROUTES
export const TRAINING_CONFIGURE_ROUTE = 'configure';
