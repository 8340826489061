import { LOGIN_ROUTE, SIGNUP_ROUTE } from '@edxp-core/utils/constants/routes.constants';
import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FirebaseService } from '@edxp-core/api/services/firebase.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirebaseApp } from '@angular/fire';
import { FirebaseAuthActionCodeInfo, FirebaseAuthUserCredential } from '@edxp-core/api/utils/firebase.utils';
import firebase from 'firebase/app';
import { FunctionsService } from '@edxp-core/api/services/functions.service';
import { AuthFunctions } from '@edxp-core/api/utils/functions.utils';
import { UserCreation } from '@edxp-auth/utils/user-creation.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends FirebaseService {
  private displaySuccessfulSignUpMsgSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public displaySuccessfulSignUpMsg$: Observable<boolean> = this.displaySuccessfulSignUpMsgSubject.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    @Inject(FirebaseApp) firebaseApp: FirebaseApp,
    private functionsService: FunctionsService
  ) {
    super(firebaseApp);
  }

  private oAuthLogin(provider: any): Promise<FirebaseAuthUserCredential | void> {
    return this.afAuth.signInWithPopup(provider);
  }

  public login(email: string, password: string): Promise<FirebaseAuthUserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  public emailSignup(payload: UserCreation): Observable<any> {
    return this.functionsService.handleHttpCallableFunction(AuthFunctions.CREATE_ACCOUNT, payload);
  }

  public googleLogin(): Promise<FirebaseAuthUserCredential | void> {
    const provider = new firebase.auth.GoogleAuthProvider();

    return this.oAuthLogin(provider);
  }

  public logout(logoutAfterAccountCreation: boolean = false): void {
    this.afAuth.signOut().then(() => {
      // clear storages and make sure no old skill trees / assessments are still there
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(logoutAfterAccountCreation ? [SIGNUP_ROUTE] : [LOGIN_ROUTE]);
    });
  }

  public updateDisplayMsgSubject(value: boolean): void {
    this.displaySuccessfulSignUpMsgSubject.next(value);
  }

  public sendResetPasswordLink(email: string): Promise<void> | undefined {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  public checkActionCode(code: string): Promise<FirebaseAuthActionCodeInfo> {
    return this.afAuth.checkActionCode(code);
  }

  public verifyEmailAddress(code: string): Promise<void> {
    return this.afAuth.applyActionCode(code);
  }

  public resetPassword(code: string, newPassword: string): Promise<void> {
    return this.afAuth.confirmPasswordReset(code, newPassword);
  }
}
