import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Question, AnsweredQuestion } from '@edxp-models/question.model';
import { AssessmentQuestion } from '@edxp-core/models/assessment.model';
import { TrainingQuestion } from '@edxp-models/training.model';

type CarouselQuestion = AssessmentQuestion | TrainingQuestion;

@Injectable({
  providedIn: 'root'
})
export class CardCarouselService {
  private displayedQuestionsSubject: BehaviorSubject<Question[]> = new BehaviorSubject<Question[]>([]);
  private questionsSubject: BehaviorSubject<CarouselQuestion[]> = new BehaviorSubject<CarouselQuestion[]>([]);
  private answeredQuestionsSubject: BehaviorSubject<AnsweredQuestion[]> = new BehaviorSubject<AnsweredQuestion[]>([]);
  private disablePreviousButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private disableNextButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showFinishButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loadingQuestionSolutionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private answerFooterHeightSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private questionChangedSubject: ReplaySubject<void> = new ReplaySubject<void>();
  private currentDisplayedQuestionIndexSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private initialized: boolean = false;

  public currentDisplayedQuestionIndex$: Observable<number> = this.currentDisplayedQuestionIndexSubject.asObservable();
  public answeredQuestions$: Observable<AnsweredQuestion[]> = this.answeredQuestionsSubject.asObservable();
  public disablePreviousButton$: Observable<boolean> = this.disablePreviousButtonSubject.asObservable();
  public disableNextButton$: Observable<boolean> = this.disableNextButtonSubject.asObservable();
  public questions$: Observable<CarouselQuestion[]> = this.questionsSubject.asObservable();
  public showFinishButton$: Observable<boolean> = this.showFinishButtonSubject.asObservable();
  public loadingQuestionSolution$: Observable<boolean> = this.loadingQuestionSolutionSubject.asObservable();
  public answerFooterHeight$: Observable<number> = this.answerFooterHeightSubject.asObservable();
  public questionChanged$: Observable<void> = this.questionChangedSubject.asObservable();

  public displayedQuestions$: Observable<Question[]> = this.displayedQuestionsSubject.asObservable();
  public questionsArray: Question[] = [];
  public currentDisplayedQuestionIndex: number = 0;

  constructor() {}

  private updateDisplayedQuestions(): void {
    this.displayedQuestionsSubject.next([
      this.questionsArray[this.currentDisplayedQuestionIndex + 2],
      this.questionsArray[this.currentDisplayedQuestionIndex + 1],
      this.questionsArray[this.currentDisplayedQuestionIndex]
    ]);
    if (this.currentDisplayedQuestionIndex === this.questionsArray.length - 1) this.showFinishButtonSubject.next(true);
    else this.showFinishButtonSubject.next(false);
    if (this.currentDisplayedQuestionIndex <= 0) this.disablePreviousButtonSubject.next(true);
    else this.disablePreviousButtonSubject.next(false);
    this.questionChangedSubject.next();
  }

  public initializeDisplayedQuestions(): void {
    this.initialized = true;
    this.questionsArray = this.questionsSubject.value;
    this.disablePreviousButtonSubject.next(true);
    this.currentDisplayedQuestionIndex = 0;
    this.displayedQuestionsSubject.next([
      this.questionsArray[this.currentDisplayedQuestionIndex + 2],
      this.questionsArray[this.currentDisplayedQuestionIndex + 1],
      this.questionsArray[this.currentDisplayedQuestionIndex]
    ]);
    if (this.currentDisplayedQuestionIndex === this.questionsArray.length - 1) this.showFinishButtonSubject.next(true);
    else this.showFinishButtonSubject.next(false);
  }

  public nextDisplayedQuestion(): void {
    if (this.disablePreviousButtonSubject.value) {
      this.disablePreviousButtonSubject.next(false);
    }
    this.currentDisplayedQuestionIndex++;
    this.currentDisplayedQuestionIndexSubject.next(this.currentDisplayedQuestionIndex);
    if (this.currentDisplayedQuestionIndex >= this.questionsArray.length) {
      this.disableNextButtonSubject.next(true);
    }
    this.updateDisplayedQuestions();
  }

  public prevDisplayedQuestion(): void {
    if (this.disableNextButtonSubject.value) {
      this.disableNextButtonSubject.next(false);
    }
    if (this.currentDisplayedQuestionIndex - 1 <= 0) {
      this.disablePreviousButtonSubject.next(true);
    }

    this.currentDisplayedQuestionIndex--;
    this.currentDisplayedQuestionIndexSubject.next(this.currentDisplayedQuestionIndex);

    this.updateDisplayedQuestions();
  }

  public setSpecificQuestion(index: number): void {
    this.currentDisplayedQuestionIndex = index;
    this.currentDisplayedQuestionIndexSubject.next(this.currentDisplayedQuestionIndex);

    this.updateDisplayedQuestions();
  }

  public setQuestions(questions: CarouselQuestion[]): void {
    this.questionsSubject.next(questions);
    this.initializeDisplayedQuestions();
  }

  public setAnsweredQuestions(questions: AnsweredQuestion[]): void {
    this.answeredQuestionsSubject.next(questions);
  }

  public toggleQuestionSolution(val: boolean): void {
    this.loadingQuestionSolutionSubject.next(val);
  }

  public setAnswerFooterHeight(height: number) {
    this.answerFooterHeightSubject.next(height);
  }
}
