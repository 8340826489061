import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (
    control.get('password')?.pristine ||
    !control.get('password')?.value ||
    control.get('confirmPassword')?.pristine ||
    !control.get('confirmPassword')?.value
  ) {
    return null;
  }

  if (control.get('password')?.value !== control.get('confirmPassword')?.value) {
    return { passwordNoMatch: true };
  }

  return null;
};

export const passwordContentValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (control.pristine || !control.value) {
    return null;
  }

  if (!/[0-9]/g.test(control.value)) {
    return { noNumber: true };
  }

  if (!/\p{Letter}/gu.test(control.value)) {
    return { noLetter: true };
  }

  return null;
};
