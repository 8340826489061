import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ShortSkill, SkillNode } from '@edxp-models/skill.model';
import { map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Hex } from '@edxp-models/hex.model';
import { levelToPalettes } from '@edxp-core/utils/constants/color-palettes.constants';
import { depthFirstSearch } from '@edxp-core/utils/skill-tree';
import { SkillsFunctions } from '@edxp-core/api/utils/functions.utils';
import { ExperienceService } from '@edxp-experience/services/experience.service';
import { FunctionsService } from '@edxp-core/api/services/functions.service';

const SESSION_STORAGE_KEY = 'skillTrees';

@Injectable({
  providedIn: 'root'
})
export class SkillTreeService {
  private subjectSkillNodeSubject: BehaviorSubject<SkillNode | undefined> = new BehaviorSubject<SkillNode | undefined>(undefined);
  private loadingSkillNodeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private currentParamSubject: BehaviorSubject<Params | null> = new BehaviorSubject<Params | null>(null);

  public loadingSkills$: Observable<SkillNode | undefined> = of(undefined);

  // Get second route from root (/skills/:skill)
  public currentParam$: Observable<Params | null> = this.currentParamSubject.asObservable();

  public skillTreesMap: Map<string, Observable<SkillNode | undefined>> = new Map<string, Observable<SkillNode | undefined>>();
  public lockedSkillTreesMap: Map<string, Observable<SkillNode | undefined>> = new Map<string, Observable<SkillNode | undefined>>();

  public subjectSkillNode$: Observable<SkillNode | undefined> = this.currentParam$.pipe(
    switchMap((params: Params | null) => {
      const skillId = params?.skill;
      if (!skillId) return of(undefined);
      const currentSubject = this.subjectSkillNodeSubject.value;
      if (!currentSubject || !depthFirstSearch(skillId, currentSubject)) {
        this.loadingSkillNodeSubject.next(true);
        this.loadingSkills$ = this.getSkillTreeForSkill(skillId).pipe(
          tap((skillNode) => {
            this.subjectSkillNodeSubject.next(skillNode);
            this.loadingSkillNodeSubject.next(false);
          })
        );

        return this.loadingSkills$;
      }

      return of(currentSubject);
    })
  );

  public loadingSkillNode$: Observable<boolean> = this.loadingSkillNodeSubject.asObservable();

  public subjectShortSkill$: Observable<ShortSkill | undefined> = this.subjectSkillNode$.pipe(
    map((subject) =>
      subject
        ? {
            id: subject.id,
            displayedName: subject.displayedName,
            initialAssessmentTaken: subject.initialAssessmentTaken,
            isFreeTrial: subject.isFreeTrial
          }
        : undefined
    )
  );

  public skillNode$: Observable<SkillNode | undefined> = this.subjectSkillNode$.pipe(
    withLatestFrom(this.currentParam$),
    map(([subjectSkillNode, params]: [SkillNode | undefined, Params | null]) => {
      if (params) {
        const skillId = params.skill;

        return depthFirstSearch(skillId, subjectSkillNode);
      }

      return undefined;
    })
  );

  public currentSkillHex$: Observable<Hex | null> = this.skillNode$.pipe(
    map((skillNode) =>
      skillNode
        ? {
            skill: skillNode,
            palette: levelToPalettes(Number(skillNode.level.levelNumber)).palette,
            tsPalette: levelToPalettes(Number(skillNode.level.levelNumber)).tsPalette,
            level: skillNode.level.levelNumber,
            isSummary: false
          }
        : null
    )
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private experienceService: ExperienceService,
    private functionsService: FunctionsService
  ) {}

  public getSubjectSkillTreeSnapshot(): SkillNode | undefined {
    return this.subjectSkillNodeSubject.value;
  }

  public getSkillTreeForLockedSkill(id: string): Observable<SkillNode | undefined> {
    const lockedSkillTreeFound$ = this.lockedSkillTreesMap.get(id);
    if (lockedSkillTreeFound$) return lockedSkillTreeFound$;

    const foundSkillTree = this.getSkillTreeFromSessionStorage(id);
    if (!foundSkillTree) {
      const fetchedLockedSkillTree$ = this.functionsService
        .handleHttpCallableFunction<SkillNode>(SkillsFunctions.GET_SKILL_TREE, {
          skillId: id
        })
        .pipe(
          tap((skillNode) => {
            this.sessionStorageSetSkillTree(skillNode);
          }),
          shareReplay({ bufferSize: 1, refCount: false })
        );

      this.lockedSkillTreesMap.set(id, fetchedLockedSkillTree$);

      return fetchedLockedSkillTree$;
    }

    const fetchedLockedSkillTree$ = of(foundSkillTree);
    this.lockedSkillTreesMap.set(id, fetchedLockedSkillTree$);

    return fetchedLockedSkillTree$;
  }

  public getSkillTreeForSkill(id: string): Observable<SkillNode | undefined> {
    const skillTreeFound$ = this.skillTreesMap.get(id);
    if (skillTreeFound$) return skillTreeFound$;

    const getScores = (skillNode: SkillNode | undefined) =>
      this.experienceService.matchSkillNodeWithUserSkillScores(of(skillNode)).pipe(shareReplay({ bufferSize: 1, refCount: false }));
    const foundSkillTree = this.getSkillTreeFromSessionStorage(id);
    if (!foundSkillTree) {
      const fetchSkillTree$ = this.functionsService
        .handleHttpCallableFunction<SkillNode>(SkillsFunctions.GET_SKILL_TREE, {
          skillId: id
        })
        .pipe(
          tap((skillNode) => this.sessionStorageSetSkillTree(skillNode)),
          switchMap(getScores),
          shareReplay({ bufferSize: 1, refCount: false })
        );

      this.skillTreesMap.set(id, fetchSkillTree$);

      return fetchSkillTree$;
    }

    const fetchSkillTree$ = of(foundSkillTree).pipe(switchMap(getScores));
    this.skillTreesMap.set(id, fetchSkillTree$);

    return fetchSkillTree$;
  }

  public depthFirstSearch(searchId: string): SkillNode | undefined {
    return depthFirstSearch(searchId, this.subjectSkillNodeSubject.value);
  }

  public sessionStorageSetSkillTree(subjectSkillNode: SkillNode): void {
    let skillTrees = sessionStorage.getItem(SESSION_STORAGE_KEY);
    if (!skillTrees) {
      const newSkillTree = {};
      newSkillTree[subjectSkillNode.id] = subjectSkillNode;
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newSkillTree));
    } else {
      skillTrees = JSON.parse(skillTrees);
      if (skillTrees) {
        skillTrees[subjectSkillNode.id] = subjectSkillNode;
        sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(skillTrees));
      }
    }
  }

  public getSkillTreeFromSessionStorage(skillId: string): SkillNode | undefined {
    const skillTrees = sessionStorage.getItem(SESSION_STORAGE_KEY);
    if (!skillTrees) return undefined;
    const parsedSkillTrees = JSON.parse(skillTrees) as { [key: string]: SkillNode };
    if (skillTrees) {
      for (const [key, value] of Object.entries(parsedSkillTrees)) {
        if (value && depthFirstSearch(skillId, value)) return value;
      }

      return undefined;
    }

    return undefined;
  }

  public setCurrentParam(params: Params): void {
    this.currentParamSubject.next(params);
  }
}
