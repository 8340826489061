export enum BackgroundTypes {
  MATH = 'math',
  SCIENCE = 'science'
}

export const DEFAULT_BACKGROUND_NAME = 'default';

export function getBackgroundType(subjectName: string): string {
  let valueToReturn: string = DEFAULT_BACKGROUND_NAME;

  Object.values(BackgroundTypes).forEach((type: string) => {
    const splitSubjectName = subjectName.split(' ');
    splitSubjectName.forEach((word) => {
      if (word.toLowerCase().includes(type)) valueToReturn = type;
    });
  });

  return valueToReturn;
}

export function generateSubjectBackgroundClass(subject: string): string {
  return `${getBackgroundType(subject)}-background`;
}

export function generateSubjectWithOpacityBackgroundClass(subject: string): string {
  return `${getBackgroundType(subject)}-background-with-opacity`;
}

export function generateSubjectWithGradientBackgroundClass(subject: string): string {
  return `${getBackgroundType(subject)}-background-with-gradient`;
}
