import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserSessionService } from '@edxp-core/api/services/user-session.service';
import { map, filter } from 'rxjs/operators';
import { User } from '@edxp-models/user.model';
import { SkillTreeService } from '@edxp-skills/services/skill-tree.service';
import { depthFirstSearch } from '@edxp-core/utils/skill-tree';
import { DASHBOARD_ROUTE, ROOT_ROUTE } from '@edxp-core/utils/constants/routes.constants';

@Injectable({
  providedIn: 'root'
})
export class TrainingGuard implements CanActivate {
  constructor(private userSessionService: UserSessionService, private skillTreeService: SkillTreeService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.userSessionService.user$.pipe(
      filter((user: User | null) => user !== null),
      map((user: User | null) => {
        const returnUrlTree = this.router.createUrlTree([ROOT_ROUTE, DASHBOARD_ROUTE]);
        if (user == null) return returnUrlTree;
        const skillId = route.params.skillId;
        const skillScoreTree = user.skillScoresTrees.find(
          (userSkillScoresTree) => depthFirstSearch(skillId, userSkillScoresTree) !== undefined
        );
        if (
          !skillScoreTree ||
          !user.unlockedSubjects.find((unlockedSubject) => unlockedSubject.id === skillScoreTree.id && !unlockedSubject.expired)
        )
          return returnUrlTree;

        return true;
      })
    );
  }
}
