import { AuthenticationRoutingModule } from './authentication-routing.module';
import { CustomInputsModule } from '@edxp-shared/custom-inputs/custom-inputs.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './../core/core.module';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';

@NgModule({
  imports: [ReactiveFormsModule, CommonModule, CoreModule, RouterModule, CustomInputsModule, AuthenticationRoutingModule],
  declarations: [
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    AuthenticationComponent
  ]
})
export class AuthenticationModule {}
