import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ASSESSMENT_ROUTE, ROOT_ROUTE } from '@edxp-core/utils/constants/routes.constants';
import { Observable } from 'rxjs';
import { ONGOING_KEY } from '../utils/constants/storage.constants';

@Injectable({
  providedIn: 'root'
})
export class OngoingAssessmentGuard implements CanActivate {
  constructor(private router: Router) {}

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // TODO we can improve this by storing ongoing assessment ids in the user info
    const localStorageOngoingAssessments = localStorage.getItem(ONGOING_KEY);

    if (!localStorageOngoingAssessments) return true;

    const parsedAssessments = JSON.parse(localStorageOngoingAssessments);

    const latestOngoing = parsedAssessments[Object.keys(parsedAssessments)[0]];

    if (latestOngoing) this.router.navigate([ROOT_ROUTE, ASSESSMENT_ROUTE, latestOngoing.id]);

    return true;
  }
}
