import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION, USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/functions';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PixelModule } from 'ngx-pixel';
import { PageUnderMaintenanceComponent } from './page-under-maintenance/page-under-maintenance.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, PageUnderMaintenanceComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    NgCircleProgressModule.forRoot({
      radius: 60,
      space: -16,
      outerStrokeWidth: 16,
      outerStrokeLinecap: 'butt',
      innerStrokeWidth: 16,
      backgroundPadding: 0,
      showBackground: true,
      titleFontSize: '20',
      unitsFontSize: '20',
      titleFontWeight: '900',
      unitsFontWeight: '900',
      showSubtitle: false,
      subtitleFontWeight: '600',
      subtitleFontSize: '10',
      clockwise: false,
      responsive: true,
      animation: true,
      animationDuration: 300
    }),
    PixelModule.forRoot({ enabled: environment.pixelId !== '', pixelId: environment.pixelId })
  ],
  providers: [
    Title,
    // TODO uncomment this when wanting to use emulator for cloud functions
    // { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    // { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5002] : undefined },
    // { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.siteKey } as RecaptchaSettings
    },
    { provide: REGION, useValue: 'asia-southeast1' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
