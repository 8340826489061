import { Injectable } from '@angular/core';
import { FunctionsService } from '@edxp-core/api/services/functions.service';
import { ErrorCodesEnum } from '@edxp-core/utils/constants/error-codes.constants';
import { ErrorFunctions } from '@edxp-core/api/utils/functions.utils';

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggerService {
  constructor(private readonly functionsService: FunctionsService) {}

  public logError(errorCode: ErrorCodesEnum, entityId: string): void {
    const foundError = localStorage.getItem(entityId);
    if (!foundError) {
      localStorage.setItem(entityId, '');
    } else return;
    this.functionsService
      .handleHttpCallableFunction(ErrorFunctions.REPORT_ERROR, {
        errorCode,
        entityId
      })
      .subscribe();
  }
}
