import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { AssessmentService } from '@edxp-pages/assessment/services/assessment.service';
import { SubscriptionCleanup } from '@edxp-core/utils/subscription-cleanup';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[edxpDarkMode]'
})
export class DarkModeDirective extends SubscriptionCleanup {
  constructor(private elementRef: ElementRef, private renderer: Renderer2, private assessmentService: AssessmentService) {
    super();
    this.renderer.addClass(this.elementRef.nativeElement, 'dark-mode-transition');
    this.assessmentService
      .getDarkMode$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isDarkMode) => {
        if (isDarkMode) this.renderer.addClass(this.elementRef.nativeElement, 'dark-mode');
        else this.renderer.removeClass(this.elementRef.nativeElement, 'dark-mode');
      });
  }
}
