import { AuthenticationComponent } from './components/authentication/authentication.component';
import { NgModule, Component } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from '@edxp-auth/components/forgot-password/forgot-password.component';
import { LoginComponent } from '@edxp-auth/components/login/login.component';
import { ResetPasswordComponent } from '@edxp-auth/components/reset-password/reset-password.component';
import { SignupComponent } from '@edxp-auth/components/signup/signup.component';
import { VerifyEmailComponent } from '@edxp-auth/components/verify-email/verify-email.component';
import { ActionCodeGuard } from '@edxp-auth/guards/action-code.guard';
import { ActionRedirectGuard } from '@edxp-auth/guards/action-redirect.guard';
import { ActionOperations } from '@edxp-auth/utils/constants/action-operations';
import {
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  FORGOT_PASS_ROUTE,
  ACTION_ROUTE,
  RESET_PASS_ROUTE,
  VERIFY_EMAIL_ROUTE,
  DASHBOARD_ROUTE
} from '@edxp-core/utils/constants/routes.constants';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([LOGIN_ROUTE]);
const redirectLoggedInToSendEmail = () => redirectLoggedInTo([DASHBOARD_ROUTE]);

const routes: Routes = [
  {
    path: '',
    component: AuthenticationComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: LOGIN_ROUTE },
      {
        path: LOGIN_ROUTE,
        component: LoginComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToSendEmail }
      },
      {
        path: SIGNUP_ROUTE,
        component: SignupComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToSendEmail }
      },
      {
        path: FORGOT_PASS_ROUTE,
        component: ForgotPasswordComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToSendEmail }
      },
      {
        path: ACTION_ROUTE,
        canActivate: [ActionRedirectGuard],
        children: []
      },
      {
        path: RESET_PASS_ROUTE,
        component: ResetPasswordComponent,
        canActivate: [ActionCodeGuard],
        data: {
          expectedOperation: ActionOperations.PASSWORD_RESET
        }
      },
      {
        path: VERIFY_EMAIL_ROUTE,
        component: VerifyEmailComponent,
        canActivate: [ActionCodeGuard],
        data: {
          expectedOperation: ActionOperations.VERIFY_EMAIL
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
