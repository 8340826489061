import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { SubscriptionCleanup } from '@edxp-core/utils/subscription-cleanup';
import { MobileDetectorService } from '@edxp-core/services/mobile-detector.service';
import { Observable, of } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';

interface WindowSize {
  width: number;
  height: number;
}

const FONT_SIZE_UPPER_BOUND = 32;
const FONT_SIZE_LOWER_BOUND = 20;

const MAX_WIDTH = 1920;
const MIN_WIDTH = 360;

const WIDTH_BASED_CONSTANT = 10;

@Directive({
  selector: '[edxpTextResize]'
})
export class TextResizeDirective extends SubscriptionCleanup implements OnInit {
  @Input() public text: string = '';

  public readonly heightMultipliers = {
    lowerBound: 1 / 10,
    upperBound: 1
  };

  public screenSize$: Observable<WindowSize> = this.detectorService.screenWidth$.pipe(
    withLatestFrom(this.detectorService.screenHeight$),
    map(([width, height]: [number, number]) => ({
      width,
      height
    }))
  );

  constructor(private elRef: ElementRef, private detectorService: MobileDetectorService, private renderer: Renderer2) {
    super();
  }

  private generateFontSizeFunction(height: number, width: number): (x: number) => number {
    const lowerTextBound = height * this.heightMultipliers.lowerBound;
    const upperTextBound = height * this.heightMultipliers.upperBound;
    // f(x) = ax + b solve this system by knowing two points:
    // (lowerTextBound, FONT_SIZE_UPPER_BOUND) and (upperTextBound, FONT_SIZE_LOWER_BOUND)
    // a = (y1 - y2) / (x1 - x2)
    // b = ((y2 * x1) - (y1 * x2)) / (x1 - x2)
    const coefA = (FONT_SIZE_UPPER_BOUND - FONT_SIZE_LOWER_BOUND) / (lowerTextBound - upperTextBound);
    const coefB = (lowerTextBound * FONT_SIZE_LOWER_BOUND - FONT_SIZE_UPPER_BOUND * upperTextBound) / (lowerTextBound - upperTextBound);

    if (width > MAX_WIDTH) width = MAX_WIDTH;
    if (width < MIN_WIDTH) width = MIN_WIDTH;

    const widthCoefA = WIDTH_BASED_CONSTANT / (MIN_WIDTH - MAX_WIDTH);
    const widthCoefB = (-MAX_WIDTH * WIDTH_BASED_CONSTANT) / (MIN_WIDTH - MAX_WIDTH);

    return (x: number) => {
      let preliminaryFontSize;
      if (x < lowerTextBound) preliminaryFontSize = FONT_SIZE_UPPER_BOUND;
      else if (x > upperTextBound) preliminaryFontSize = FONT_SIZE_LOWER_BOUND;
      else preliminaryFontSize = coefA * x + coefB;

      const sizeToSubstract = widthCoefA * width + widthCoefB;

      return preliminaryFontSize - sizeToSubstract;
    };
  }

  public ngOnInit() {
    const imgRegex = /<img.*?>/g;
    const textToFit = this.text.replace(imgRegex, ''); // Remove images from text size

    this.screenSize$.pipe(takeUntil(this.destroy$)).subscribe((screenSize: WindowSize) => {
      const fontSizeFunction = this.generateFontSizeFunction(screenSize.height, screenSize.width);
      const fontSize = fontSizeFunction(textToFit.length);
      this.renderer.setStyle(this.elRef.nativeElement, 'font-size', `${fontSize}px`);
      this.renderer.setStyle(this.elRef.nativeElement, 'line-height', `${fontSize * 1.5}px`);
    });
  }
}
