export enum AuthErrorsLogin {
  WRONG_PASSWORD = 'auth/wrong-password',
  USER_NOT_FOUND = 'auth/user-not-found',
  USER_DISABLED = 'auth/user-disabled'
}

export enum AuthErrorsSignup {
  WEAK_PASSWORD = 'auth/weak-password',
  EMAIL_EXISTS = 'auth/email-already-in-use',
  INVALID_RECAPTCHA = 'auth/invalid-recaptcha'
}

export enum AuthErrorsAction {
  INVALID_TOKEN = 'auth/invalid-action-code',
  EXPIRED_TOKEN = 'auth/expired-action-code',
  USER_NOT_FOUND = 'auth/user-not-found',
  USER_DISABLED = 'auth/user-disabled'
}
