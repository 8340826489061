import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@edxp-auth/services/auth.service';
import { AuthErrorsLogin } from '@edxp-auth/utils/constants/auth-errors';
import { UserSessionService } from '@edxp-core/api/services/user-session.service';
import { FirebaseAuthError } from '@edxp-core/api/utils/firebase.utils';
import { DASHBOARD_ROUTE, FORGOT_PASS_ROUTE, SIGNUP_ROUTE } from '@edxp-core/utils/constants/routes.constants';
import { SubscriptionCleanup } from '@edxp-core/utils/subscription-cleanup';
import { TranslateService } from '@ngx-translate/core';
import { take, takeWhile } from 'rxjs/operators';
import { CustomDialogService } from '@edxp-core/services/custom-dialog.service';
import { LoadingStateComponent } from '@edxp-pages/assessment/components/loading-state/loading-state.component';

@Component({
  selector: 'edxp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends SubscriptionCleanup implements OnInit {
  public loginForm: FormGroup = new FormGroup({});

  public errorMsg: string = '';
  public emailNotVerified: boolean = false;
  public loading: boolean = false;

  public googleLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private userSessionService: UserSessionService,
    private titleService: Title,
    private translate: TranslateService,
    private dialogService: CustomDialogService
  ) {
    super();
  }

  @HostListener('window:keyup.enter') public onEnterKeyUp() {
    this.signIn();
  }

  public ngOnInit(): void {
    this.translate
      .get('PAGES.LOGIN')
      .pipe(take(1))
      .subscribe((title) => {
        this.titleService.setTitle(title);
      });

    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required])]
    });
  }

  public signIn(): void {
    if (this.loginForm.valid) {
      this.resetMessages();
      this.loading = true;
      this.authService
        .login(this.loginForm?.controls.email.value, this.loginForm?.controls.password.value)
        .then(() => {
          if (!this.userSessionService.firebaseApp.auth().currentUser?.emailVerified) {
            this.emailNotVerified = true;
            this.authService.sendVerificationEmail();
            this.authService.logout();
          } else {
            this.router.navigate([DASHBOARD_ROUTE]);
          }
        })
        .catch((err: FirebaseAuthError) => {
          switch (err.code) {
            case AuthErrorsLogin.WRONG_PASSWORD: {
              this.errorMsg = 'AUTHENTICATION.ERRORS.PASSWORD.WRONG-PASSWORD';
              break;
            }
            case AuthErrorsLogin.USER_NOT_FOUND: {
              this.errorMsg = 'AUTHENTICATION.ERRORS.EMAIL.NOT-FOUND';
              break;
            }
            case AuthErrorsLogin.USER_DISABLED: {
              this.errorMsg = 'AUTHENTICATION.ERRORS.ACCOUNT-DISABLED';
              break;
            }
            default: {
              this.errorMsg = 'AUTHENTICATION.ERRORS.UNKNOWN';
              break;
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.userSessionService.reloadUser();
        });
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  public resetMessages(): void {
    this.emailNotVerified = false;
    this.authService.updateDisplayMsgSubject(false);
    this.errorMsg = '';
  }

  public googleSignIn(): void {
    this.errorMsg = '';
    this.authService
      .googleLogin()
      .then((r) => {
        this.googleLoading = true;
        this.userSessionService.reloadUser();
        this.userSessionService.user$
          .pipe(takeWhile((user) => !user || (!user.unlockedSubjects && !user.level), true))
          .subscribe((user) => {
            if (user?.unlockedSubjects || user?.level) {
              this.googleLoading = false;
              this.router.navigate([DASHBOARD_ROUTE]);
            } else {
              setTimeout(() => this.userSessionService.reloadUser(), 1000);
            }
          });
      })
      .catch((err: FirebaseAuthError) => {
        switch (err.code) {
          case AuthErrorsLogin.USER_DISABLED: {
            this.errorMsg = 'AUTHENTICATION.ERRORS.ACCOUNT-DISABLED';
            break;
          }
          default: {
            this.errorMsg = 'AUTHENTICATION.ERRORS.UNKNOWN';
            break;
          }
        }
      });
  }

  public goToSignup(): void {
    this.resetMessages();
    this.router.navigate([SIGNUP_ROUTE]);
  }

  public goToForgotPass(): void {
    this.errorMsg = '';
    this.loginForm.reset();
    this.router.navigate([FORGOT_PASS_ROUTE]);
  }
}
