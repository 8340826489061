import { Injectable, OnDestroy } from '@angular/core';
import { SubscriptionCleanup } from '@edxp-core/utils/subscription-cleanup';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

/**
 * Before using this service, try to do it with media queries first.
 */
@Injectable({
  providedIn: 'root'
})
export class MobileDetectorService extends SubscriptionCleanup implements OnDestroy {
  // See theme/utilities/_variables.scss

  // private readonly screenWidthSubject: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // public readonly screenWidth$: Observable<number> = this.screenWidthSubject;

  private readonly screenWidthSubject: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
  private readonly screenHeightSubject: BehaviorSubject<number> = new BehaviorSubject<number>(window.innerHeight);

  public readonly screenWidth$: Observable<number> = this.screenWidthSubject.asObservable();
  public readonly screenHeight$: Observable<number> = this.screenHeightSubject.asObservable();
  public readonly breakpoints = { xs: 426, sm: 768, md: 1024, lg: 1440, xl: 1980 };

  public readonly isPhone$: Observable<boolean> = this.screenWidth$.pipe(
    map((width) => width < this.breakpoints.xs),
    distinctUntilChanged()
  );

  public readonly isSmallScreen$: Observable<boolean> = this.screenWidth$.pipe(
    map((width) => width < this.breakpoints.sm),
    distinctUntilChanged()
  );

  public readonly isMobile$: Observable<boolean> = this.screenWidth$.pipe(
    map((width) => width <= this.breakpoints.md),
    distinctUntilChanged()
  );

  public readonly isDesktop$: Observable<boolean> = this.isMobile$.pipe(
    map((isMobile) => !isMobile),
    distinctUntilChanged()
  );

  constructor() {
    super();
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.screenWidthSubject.next(window.innerWidth);
        this.screenHeightSubject.next(window.innerHeight);
      });
  }

  /**
   * This is a _snapshot_ value. Use this in components only when handling event stuff (eg user clicked, do something for mobile, or
   * something else for desktop). Also for opening angular material dialogs, where observables can't be used.
   * <p>
   * Always write `mobileDetectorService.isPhone`, never save this value in a property (it can and it will change in the same view).
   * <p>
   * If you want to use it in a component template, don't; use {@link isPhone$} with the async pipe instead.
   */
  public get isPhone(): boolean {
    return this.screenWidthSubject.value < this.breakpoints.xs;
  }

  public get isSmallScreen(): boolean {
    return this.screenWidthSubject.value <= this.breakpoints.sm;
  }

  /** @see isPhone */
  public get isMobile(): boolean {
    return this.screenWidthSubject.value <= this.breakpoints.md;
  }

  /** @see isPhone */
  public get isDesktop(): boolean {
    return !this.isMobile;
  }
}
