<mat-form-field appearance="outline">
  <mat-label for="country">
    {{ 'PROFILE.PERSONAL-INFO.COUNTRY' | translate }}
  </mat-label>
  <mat-select id="country"
    [required]="required"
    [formControl]="countryControl">
    <mat-form-field appearance="standard"
      class="search-field">
      <i matPrefix
        class="ti ti-search"></i>
      <input #searchInput
        matInput
        type="text"
        [formControl]="searchControl"
        [placeholder]="'PROFILE.PERSONAL-INFO.COUNTRY' | translate" />
    </mat-form-field>

    <div class="scroll-container"
      *ngIf="filtered$ | async as filtered">
      <mat-option *ngIf="filtered.length === 0"
        [disabled]="true"
        class="empty-option">{{ 'COUNTRY-SELECTOR.SEARCH' | translate }}</mat-option>
      <mat-option *ngFor="let country of filtered"
        [value]="country.name">
        <span class="emoji">{{ country.emoji }}</span>
        {{ country.name }}
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
