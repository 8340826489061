import { FunctionsService } from '@edxp-core/api/services/functions.service';
import { map } from 'rxjs/operators';
import { UserSessionService } from '@edxp-core/api/services/user-session.service';
import { Skill, SkillNode } from '@edxp-core/models/skill.model';
import { Observable, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '@edxp-core/models/user.model';
import { Level } from '@edxp-experience/models/level.model';
import { depthFirstSearch } from '@edxp-core/utils/skill-tree';
import { SkillScoreTree } from '@edxp-experience/models/user-skill-score.model';

export interface QuestionAnswer {
  questionId: string;
  correct: boolean;
  isAssessment: boolean;
  skillId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExperienceService {
  constructor(private userSessionService: UserSessionService, private functionsService: FunctionsService) {}

  private matchLevel(userLevel: Level | undefined): Level {
    return {
      xpGained: userLevel ? userLevel.xpGained : 0,
      levelNumber: userLevel ? userLevel.levelNumber : 1,
      maxXp: userLevel ? userLevel.maxXp : 100,
      progress: userLevel ? Math.floor((userLevel.xpGained / userLevel.maxXp) * 100) : 0
    };
  }

  private populateTreeLevel(skillNode: SkillNode, treeToPair: SkillScoreTree): SkillNode {
    if (skillNode.children.length > 0) {
      skillNode.children = skillNode.children.map((childNode) => {
        const foundScoreTreeToPair = treeToPair.children.find((childScoreTree) => childScoreTree.id === childNode.id);
        if (foundScoreTreeToPair) {
          return this.populateTreeLevel(childNode, foundScoreTreeToPair);
        } else {
          // TODO see if we stil need this warn
          // console.warn("Trees don't match");

          return this.populateTreeLevel(childNode, treeToPair);
        }
      });
    }

    if (skillNode.id === treeToPair.id) {
      skillNode.level = this.matchLevel(treeToPair.level);
    } else {
      skillNode.level = {
        levelNumber: 1,
        xpGained: 1,
        maxXp: 100,
        progress: 0
      };
    }
    skillNode.isFreeTrial = treeToPair.isFreeTrial;

    return skillNode;
  }

  public matchSkillsWithUserSkillScores(skills$: Observable<Skill[]>): Observable<Skill[]> {
    return combineLatest([this.userSessionService.user$.pipe(map((user: User | null) => user?.skillScoresTrees)), skills$]).pipe(
      map(([skillScoresTrees, skills]: [SkillScoreTree[] | undefined, Skill[]]) => {
        skills.forEach((skill: Skill) => {
          const subjectSkillScore = skillScoresTrees?.find((skScoreTree) => skScoreTree.id === skill.parent[0]);
          if (subjectSkillScore) {
            const userSkillScore = depthFirstSearch(skill.id, subjectSkillScore);
            skill.level = this.matchLevel(userSkillScore?.level);
          }
        });

        return skills;
      })
    );
  }

  public matchSkillNodeWithUserSkillScores(skillNode$: Observable<SkillNode | undefined>): Observable<SkillNode | undefined> {
    return combineLatest([this.userSessionService.user$.pipe(map((user: User | null) => user?.skillScoresTrees)), skillNode$]).pipe(
      map(([skillScoresTrees, skillNode]: [SkillScoreTree[] | undefined, SkillNode | undefined]) => {
        if (!skillNode || !skillScoresTrees) return undefined;

        if (skillScoresTrees) {
          const pairedSkillScoresTree = skillScoresTrees.find((skScoreTree) => skScoreTree.id === skillNode?.id);
          if (!pairedSkillScoresTree) return undefined;
          skillNode = this.populateTreeLevel(skillNode, pairedSkillScoresTree);

          return skillNode;
        }

        return undefined;
      })
    );
  }

  public fillSkillLevelsUpstream(skills: Skill[]): Skill[] {
    // take each skill
    // find child skills
    // calculate average of levels
    // return calculated level

    skills.forEach((skill) => {
      const childSkills = skills.filter((sk) => sk.parentId === skill.id);
      const childLevels = childSkills.map((sk) => sk.level.levelNumber);
      if (childSkills.length > 0) {
        const childLevelAverage = childLevels.reduce((a, b) => a + b);
        const skillLevel = Math.round(childLevelAverage / childSkills.length) > 0 ? Math.round(childLevelAverage / childSkills.length) : 1;
        skill.level.levelNumber = skillLevel;
        skill.level.progress =
          Math.round((skill.level.xpGained * 100) / skill.level.maxXp) > 0
            ? Math.round((skill.level.xpGained * 100) / skill.level.maxXp)
            : 1;
      }
    });

    return skills;
  }
}
