import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@edxp-core/core.module';
import { FileInputComponent } from './file-input/file-input.component';
import { CountrySelectorComponent } from './country-selector/country-selector.component';

const COMPONENTS = [FileInputComponent, CountrySelectorComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, CoreModule, ReactiveFormsModule],
  exports: [...COMPONENTS]
})
export class CustomInputsModule {}
