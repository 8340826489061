import { Injectable } from '@angular/core';
declare const window: Window;

interface Window {
  HubSpotConversations?: {
    widget: {
      open: () => void;
      close: () => void;
      // Add any other methods you need to use here
      sendMessage?: (message: string) => void; // This is hypothetical and may not exist
    };
  };
}
@Injectable({
  providedIn: 'root'
})
export class HubspotService {
  // Webstorm is very confused by this syntax if the parens are removed, so tell prettier to keep them
  private readonly hasHubSpot: boolean = /* prettier-ignore */ ('_hsq' in window) && !!(window as any)._hsq;

  constructor() {}

  public hideHubspot(): void {
    if (!document.body.classList.contains('hubspot-hidden')) {
      document.body.classList.add('hubspot-hidden');
    }
  }

  public showHubspot(): void {
    document.body.classList.remove('hubspot-hidden');
  }

  public openHubspotConversation(): void {
    const hubSpotConversations = window['HubSpotConversations'];
    console.log(hubSpotConversations?.widget);
    if (hubSpotConversations && hubSpotConversations.widget) {
      // Ensure the widget and sendMessage method are available
      if (typeof hubSpotConversations.widget.open === 'function') {
        hubSpotConversations.widget.open();
      }

      if (typeof hubSpotConversations.widget.sendMessage === 'function') {
        try {
          hubSpotConversations.widget.sendMessage('Hello, I have a question about...');
        } catch (e) {
          console.error('Failed to send message in HubSpot chat:', e);
        }
      } else {
        console.log('sendMessage method is not available on HubSpot Conversations widget.');
      }
    } else {
      console.log('HubSpot Conversations widget is not available.');
    }
  }

  public closeHubspotConversation(): void {
    const hubSpotConversations = window['HubSpotConversations'];

    if (hubSpotConversations && hubSpotConversations.widget) {
      // Ensure the widget and sendMessage method are available
      if (typeof hubSpotConversations.widget.open === 'function') {
        hubSpotConversations.widget.close();
      }
    } else {
      console.log('HubSpot Conversations widget is not available.');
    }
    this.hideHubspot();
  }
}
