import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@edxp-auth/services/auth.service';
import { AuthErrorsAction } from '@edxp-auth/utils/constants/auth-errors';
import { LOGIN_ROUTE } from '@edxp-core/utils/constants/routes.constants';
import { FirebaseAuthError } from '@edxp-core/api/utils/firebase.utils';

enum Steps {
  LOADING,
  SUCCESS,
  ERROR
}

@Component({
  selector: 'edxp-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  public errorMsg: string = '';

  public step: Steps = Steps.LOADING;

  public Steps = Steps;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {}

  public ngOnInit(): void {
    const oobCode = this.route.snapshot.queryParamMap.get('oobCode');
    if (!oobCode) {
      this.errorMsg = 'AUTHENTICATION.ERRORS.UNKNOWN';

      return;
    }
    this.authService
      .verifyEmailAddress(oobCode)
      .then(() => {
        this.step = Steps.SUCCESS;
      })
      .catch((err: FirebaseAuthError) => {
        this.step = Steps.ERROR;
        switch (err.code) {
          case AuthErrorsAction.INVALID_TOKEN: {
            this.errorMsg = 'AUTHENTICATION.ERRORS.TOKEN.INVALID';
            break;
          }
          case AuthErrorsAction.EXPIRED_TOKEN: {
            this.errorMsg = 'AUTHENTICATION.ERRORS.TOKEN.EXPIRED';
            break;
          }
          case AuthErrorsAction.USER_DISABLED: {
            this.errorMsg = 'AUTHENTICATION.ERRORS.ACCOUNT-DISABLED';
            break;
          }
          default: {
            this.errorMsg = 'AUTHENTICATION.ERRORS.UNKNOWN';
            break;
          }
        }
      });
  }

  public goToLogin(): void {
    this.router.navigate([LOGIN_ROUTE]);
  }
}
