<div class="page-container">
  <img src="/assets/images/banner-visual.png"
    class="background">
  <div class="center-grid-element">
    <div class="col-container">
      <div class="title-container">
        <!-- TODO: this will be replaced by logo -->
        <h1>EdXP</h1>
      </div>
      <div class="verify-email-container"
        [ngSwitch]="step">
        <h2 class="success-title"
          *ngSwitchCase="Steps.SUCCESS">{{ 'AUTHENTICATION.VERIFY-EMAIL.SUCCESS' | translate }}</h2>
        <h2 class="loading-title"
          *ngSwitchCase="Steps.LOADING">{{ 'AUTHENTICATION.VERIFY-EMAIL.LOADING' | translate }}</h2>
        <h2 class="error-title"
          *ngSwitchCase="Steps.ERROR">{{ errorMsg | translate }}</h2>
        <p class="text-button"
          (click)="goToLogin()">{{ 'AUTHENTICATION.VERIFY-EMAIL.TO-LOGIN' | translate }}</p>
      </div>
    </div>
  </div>
</div>
