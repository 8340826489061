<div class="page-container">
  <img src="/assets/images/banner-visual.png"
    class="background">
  <div class="center-grid-element">
    <div class="col-container">
      <div class="title-container">
        <div class="edxp-logo text-logo-black"></div>
      </div>
      <form [formGroup]="resetPassForm">
        <mat-form-field appearance="outline"
          class="centered-suffix-text">
          <mat-label for="password">{{ 'AUTHENTICATION.SHARED.PASSWORD' | translate }}<span class="text-danger">*</span>
          </mat-label>
          <input #loginInput
            matInput
            type="password"
            autocomplete="password"
            id="password"
            formControlName="password">
          <mat-error *ngIf="resetPassForm.hasError('required', 'password')">
            {{ 'AUTHENTICATION.ERRORS.PASSWORD.REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="resetPassForm.hasError('minlength', 'password') && !resetPassForm.hasError('required', 'password')">
            {{ 'AUTHENTICATION.ERRORS.PASSWORD.MINLENGTH' | translate }}
          </mat-error>
          <mat-error
            *ngIf="resetPassForm.hasError('noLetter', 'password') && !resetPassForm.hasError('required', 'password') && !resetPassForm.hasError('minlength', 'password')">
            {{ 'AUTHENTICATION.ERRORS.PASSWORD.NO-LETTER' | translate }}
          </mat-error>
          <mat-error
            *ngIf="resetPassForm.hasError('noNumber', 'password') && !resetPassForm.hasError('required', 'password') && !resetPassForm.hasError('minlength', 'password')">
            {{ 'AUTHENTICATION.ERRORS.PASSWORD.NO-NUMBER' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline"
          class="centered-suffix-text">
          <mat-label for="confirm-password">{{ 'AUTHENTICATION.SIGNUP.CONFIRM-PASSWORD' | translate }}<span class="text-danger">*</span>
          </mat-label>
          <input #loginInput
            matInput
            type="password"
            autocomplete="password"
            id="confirm-password"
            formControlName="confirmPassword">
          <mat-error *ngIf="resetPassForm.hasError('required', 'confirmPassword')">
            {{ 'AUTHENTICATION.ERRORS.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="error-container"
          *ngIf="resetPassForm.errors?.passwordNoMatch && showPasswordNoMatchError">
          {{ 'AUTHENTICATION.ERRORS.PASSWORD.NO-MATCH' | translate }}
        </div>
        <div class="error-container"
          *ngIf="errorMsg">
          {{ errorMsg | translate }}
        </div>
        <div class="success-container"
          *ngIf="success">
          {{ 'AUTHENTICATION.RESET-PASSWORD.SUCCESS' | translate }}
        </div>
      </form>
      <div class="form-actions-container">
        <button mat-raised-button
          color="primary"
          (click)="resetPass()">
          {{ 'AUTHENTICATION.RESET-PASSWORD.BUTTON' | translate }}
        </button>
        <div class="to-login">
          <span class="text-button"
            (click)="goToLogin()">
            {{ 'AUTHENTICATION.RESET-PASSWORD.TO-LOGIN' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
