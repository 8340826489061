import { Pipe, PipeTransform } from '@angular/core';

import * as katex from 'katex';
import { ErrorLoggerService } from '@edxp-core/services/error-logger.service';
import { ErrorCodesEnum } from '@edxp-core/utils/constants/error-codes.constants';

const unsupportedCharacters = [
  {
    toReplace: '&lt;',
    replaceWith: '<'
  },
  {
    toReplace: '&gt;',
    replaceWith: '>'
  },
  {
    toReplace: '&amp;',
    replaceWith: '&'
  }
];

export enum QuestionTextLocation {
  QUESTION_TEXT,
  QUESTION_ANSWER,
  QUESTION_SOLUTION
}

@Pipe({
  name: 'katex'
})
export class KatexPipe implements PipeTransform {
  constructor(private readonly errorLoggerService: ErrorLoggerService) {}

  public transform(value: string, questionId: string, textLocation: QuestionTextLocation): string {
    // Remove font size from spans
    const fontSizeRegex = new RegExp(/font-size:[0-9]+[a-zA-Z]+?(?=[ ;\"])/gm);
    value = value.replace(fontSizeRegex, '');
    // Replace characters that katex doesn't parse for some reason
    unsupportedCharacters.forEach((pair) => (value = value.replace(new RegExp(pair.toReplace, 'g'), pair.replaceWith)));
    // Regex expression to detect math-tex spans and replace them by generated ones
    const regex = new RegExp(/<span class=[\"\']math-tex[\"\'].*?>\\\(((.|\n)*?)\\\)<\/span>/gm);
    let matchedSpanWithCaptureGroup;
    try {
      while ((matchedSpanWithCaptureGroup = regex.exec(value)) !== null) {
        if (matchedSpanWithCaptureGroup[1]) {
          const renderedLatex = katex.renderToString(matchedSpanWithCaptureGroup[1], {
            throwOnError: true,
            displayMode: true
          });
          value = value.replace(matchedSpanWithCaptureGroup[0], renderedLatex);
        }
      }
    } catch (e) {
      console.log(e);
      switch (textLocation) {
        case QuestionTextLocation.QUESTION_TEXT:
          this.errorLoggerService.logError(ErrorCodesEnum.BAD_KATEX_TEXT, questionId);
          break;
        case QuestionTextLocation.QUESTION_ANSWER:
          this.errorLoggerService.logError(ErrorCodesEnum.BAD_KATEX_ANSWERS, questionId);
          break;
        case QuestionTextLocation.QUESTION_SOLUTION:
          this.errorLoggerService.logError(ErrorCodesEnum.BAD_KATEX_SOLUTION, questionId);
          break;
      }
    }

    return value;
  }
}
