<form [formGroup]="signupForm"
  (keyup.enter)="signUp()">
  <div class="multiple-field">
    <mat-form-field appearance="outline">
      <mat-label for="firstName">{{ 'AUTHENTICATION.SIGNUP.FIRST-NAME' | translate }}<span class="text-danger">*</span>
      </mat-label>
      <input matInput
        type="email"
        autocomplete="email"
        id="firstName"
        formControlName="firstName">
      <mat-error *ngIf="signupForm.hasError('required', 'firstName')">
        {{ 'AUTHENTICATION.ERRORS.NAMES.FIRST-REQUIRED' | translate }}
      </mat-error>
      <mat-error *ngIf="signupForm.hasError('maxlength', 'firstName') ">
        {{ 'AUTHENTICATION.ERRORS.NAMES.MAXLENGTH' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label for="lastName">{{ 'AUTHENTICATION.SIGNUP.LAST-NAME' | translate }}<span class="text-danger">*</span>
      </mat-label>
      <input matInput
        type="email"
        autocomplete="email"
        id="lastName"
        formControlName="lastName">
      <mat-error *ngIf="signupForm.hasError('required', 'lastName')">
        {{ 'AUTHENTICATION.ERRORS.NAMES.LAST-REQUIRED' | translate }}
      </mat-error>
      <mat-error *ngIf="signupForm.hasError('maxlength', 'lastName')">
        {{ 'AUTHENTICATION.ERRORS.NAMES.MAXLENGTH' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <mat-form-field appearance="outline">
    <mat-label for="email">{{ 'AUTHENTICATION.SHARED.EMAIL' | translate }}<span class="text-danger">*</span>
    </mat-label>
    <input matInput
      type="email"
      autocomplete="email"
      id="email"
      formControlName="email">
    <mat-error *ngIf="signupForm.hasError('required', 'email')">
      {{ 'AUTHENTICATION.ERRORS.EMAIL.REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="signupForm.hasError('email', 'email') && !signupForm.hasError('required', 'email')">
      {{ 'AUTHENTICATION.ERRORS.EMAIL.INVALID' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline"
    class="centered-suffix-text">
    <mat-label for="password">{{ 'AUTHENTICATION.SHARED.PASSWORD' | translate }}<span class="text-danger">*</span>
    </mat-label>
    <input #loginInput
      matInput
      type="password"
      autocomplete="password"
      id="password"
      formControlName="password">
    <mat-error *ngIf="signupForm.hasError('required', 'password')">
      {{ 'AUTHENTICATION.ERRORS.PASSWORD.REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="signupForm.hasError('minlength', 'password') && !signupForm.hasError('required', 'password')">
      {{ 'AUTHENTICATION.ERRORS.PASSWORD.MINLENGTH' | translate }}
    </mat-error>
    <mat-error
      *ngIf="signupForm.hasError('noLetter', 'password') && !signupForm.hasError('required', 'password') && !signupForm.hasError('minlength', 'password')">
      {{ 'AUTHENTICATION.ERRORS.PASSWORD.NO-LETTER' | translate }}
    </mat-error>
    <mat-error
      *ngIf="signupForm.hasError('noNumber', 'password') && !signupForm.hasError('required', 'password') && !signupForm.hasError('minlength', 'password')">
      {{ 'AUTHENTICATION.ERRORS.PASSWORD.NO-NUMBER' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline"
    class="centered-suffix-text">
    <mat-label for="confirm-password">{{ 'AUTHENTICATION.SIGNUP.CONFIRM-PASSWORD' | translate }}<span class="text-danger">*</span>
    </mat-label>
    <input #loginInput
      matInput
      type="password"
      autocomplete="password"
      id="confirm-password"
      formControlName="confirmPassword">
    <mat-error *ngIf="signupForm.hasError('required', 'confirmPassword')">
      {{ 'AUTHENTICATION.ERRORS.REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  <!--        <div class="multiple-field">-->
  <!-- TODO: uncomment when using years  -->
  <!--          <mat-form-field appearance="outline">-->
  <!--            <mat-label for="year">-->
  <!--              {{ 'PROFILE.PERSONAL-INFO.YEAR' | translate }}-->
  <!--            </mat-label>-->
  <!--            <mat-select id="year"-->
  <!--              formControlName="year">-->
  <!--              &lt;!&ndash; Dummy content &ndash;&gt;-->
  <!--              <mat-option *ngFor="let year of years"-->
  <!--                [value]="year.id">-->
  <!--                {{ year.year }}-->
  <!--              </mat-option>-->
  <!--            </mat-select>-->
  <!--            <mat-error *ngIf="signupForm.hasError('required', 'year')">-->
  <!--              {{ 'AUTHENTICATION.ERRORS.REQUIRED' | translate }}-->
  <!--            </mat-error>-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!-- <edxp-country-selector [countryControl]="$any(signupForm.get('country'))"
    [required]="true"></edxp-country-selector> -->
  <re-captcha formControlName="recaptcha">
  </re-captcha>
  <div class="error-container"
    *ngIf="signupForm.errors?.passwordNoMatch && showPasswordNoMatchError">
    {{ 'AUTHENTICATION.ERRORS.PASSWORD.NO-MATCH' | translate }}
  </div>
  <div class="error-container"
    *ngIf="errorMsg">
    {{ errorMsg | translate }}
  </div>
</form>
<div class="form-actions-container"
  *ngIf="!(displaySuccessfulSignUpMsg$ | async)">
  <button mat-raised-button
    color="primary"
    (click)="signUp()"
    [disabled]="googleLoading || loading">
    <ng-container *ngIf="!loading; else loadingTpl">{{ 'AUTHENTICATION.SHARED.SIGNUP-BTN' | translate }}</ng-container>
    <ng-template #loadingTpl><img src="assets/images/ball-triangle-primary.svg">
      {{ 'AUTHENTICATION.SHARED.LOADING' | translate }}
    </ng-template>
  </button>
  <span class="or-message">{{ 'AUTHENTICATION.LOGIN.OR' | translate }}</span>
  <button mat-flat-button
    color="secondary"
    class="google-button"
    [disabled]="googleLoading || loading"
    (click)="googleSignIn()">
    <span *ngIf="!googleLoading"
      class="google-icon"></span>
    <ng-container *ngIf="!googleLoading; else loadingTplGoogle">{{ 'AUTHENTICATION.SIGNUP.GOOGLE' | translate }}</ng-container>
    <ng-template #loadingTplGoogle><img src="assets/images/ball-triangle-primary.svg">
    </ng-template>
  </button>
</div>

<div class="confirmation-email-sent-container"
  *ngIf="(displaySuccessfulSignUpMsg$ | async)">
  <div class="icon-container">
    <i class="ti ti-circle-check"></i>
  </div>
  <span class="message">{{ 'AUTHENTICATION.SIGNUP.SUCCESS-SIGNUP' | translate }}&nbsp;
    <a [routerLink]="'/login'">{{ 'AUTHENTICATION.SIGNUP.GO-TO-LOGIN' | translate }}</a>
  </span>
</div>
