import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@edxp-auth/services/auth.service';
import { ROOT_ROUTE } from '@edxp-core/utils/constants/routes.constants';
import { FirebaseAuthActionCodeInfo, FirebaseAuthError } from '@edxp-core/api/utils/firebase.utils';

@Injectable({
  providedIn: 'root'
})
export class ActionCodeGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // This route checks whether an action code is valid when accessing a certain operation (email verification, password reset etc.)
    // If it is not, redirect to login
    const oobCode = route.queryParamMap.get('oobCode') ?? '';

    return new Promise((resolve, reject) => {
      this.authService
        .checkActionCode(oobCode)
        .then((info: FirebaseAuthActionCodeInfo) => {
          if (route.data?.expectedOperation === info.operation) {
            resolve(true);
          } else {
            resolve(this.router.parseUrl(ROOT_ROUTE));
          }
        })
        .catch((err: FirebaseAuthError) => resolve(this.router.parseUrl(ROOT_ROUTE)));
    });
  }
}
