import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Country } from 'countries-list';

export type Countries = Record<string, Country>;

export interface CountryOption extends Country {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  public readonly countries$: Observable<Countries> = from(
    import('countries-list/dist/countries.emoji.min.json').then((m) => (m as any).default)
  ).pipe(shareReplay({ bufferSize: 1, refCount: false }));

  public readonly countriesList$: Observable<CountryOption[]> = this.countries$.pipe(
    map((countries) =>
      Object.entries(countries).map(([countryId, country]) => ({
        id: countryId,
        ...country
      }))
    )
  );

  constructor() {}
}
