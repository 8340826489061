import {
  AUTH_ROUTE,
  DASHBOARD_ROUTE,
  PROFILE_ROUTE,
  SKILLS_ROUTE,
  ASSESSMENT_ROUTE,
  TRAINING_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  FORGOT_PASS_ROUTE,
  ACTION_ROUTE,
  VERIFY_EMAIL_ROUTE,
  RESET_PASS_ROUTE,
  SUBJECT_ROUTE
} from './core/utils/constants/routes.constants';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationModule } from '@edxp-auth/authentication.module';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { LoginComponent } from '@edxp-auth/components/login/login.component';
import { SignupComponent } from '@edxp-auth/components/signup/signup.component';
import { ForgotPasswordComponent } from '@edxp-auth/components/forgot-password/forgot-password.component';
import { ActionRedirectGuard } from '@edxp-auth/guards/action-redirect.guard';
import { VerifyEmailComponent } from '@edxp-auth/components/verify-email/verify-email.component';
import { ActionCodeGuard } from '@edxp-auth/guards/action-code.guard';
import { ActionOperations } from '@edxp-auth/utils/constants/action-operations';
import { ResetPasswordComponent } from '@edxp-auth/components/reset-password/reset-password.component';
import { OngoingAssessmentGuard } from '@edxp-pages/assessment/guards/ongoing-assessment.guard';
import { TrainingGuard } from '@edxp-pages/training/guards/training.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([AUTH_ROUTE]);
const redirectLoggedInToSendEmail = () => redirectLoggedInTo([DASHBOARD_ROUTE]);

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: LOGIN_ROUTE },
  // {
  //   path: LOGIN_ROUTE,
  //   component: LoginComponent,
  //   canActivate: [AngularFireAuthGuard],
  //   data: { authGuardPipe: redirectLoggedInToSendEmail }
  // },
  // {
  //   path: SIGNUP_ROUTE,
  //   component: SignupComponent,
  //   canActivate: [AngularFireAuthGuard],
  //   data: { authGuardPipe: redirectLoggedInToSendEmail }
  // },
  // {
  //   path: FORGOT_PASS_ROUTE,
  //   component: ForgotPasswordComponent,
  //   canActivate: [AngularFireAuthGuard],
  //   data: { authGuardPipe: redirectLoggedInToSendEmail }
  // },
  // {
  //   path: ACTION_ROUTE,
  //   canActivate: [ActionRedirectGuard],
  //   children: []
  // },
  // {
  //   path: RESET_PASS_ROUTE,
  //   component: ResetPasswordComponent,
  //   canActivate: [ActionCodeGuard],
  //   data: {
  //     expectedOperation: ActionOperations.PASSWORD_RESET
  //   }
  // },
  // {
  //   path: VERIFY_EMAIL_ROUTE,
  //   component: VerifyEmailComponent,
  //   canActivate: [ActionCodeGuard],
  //   data: {
  //     expectedOperation: ActionOperations.VERIFY_EMAIL
  //   }
  // },
  { path: '', pathMatch: 'full', redirectTo: AUTH_ROUTE },
  {
    path: AUTH_ROUTE,
    loadChildren: () => import('app/authentication/authentication.module').then((m) => m.AuthenticationModule)
  },
  {
    path: DASHBOARD_ROUTE,
    loadChildren: () => import('app/pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AngularFireAuthGuard, OngoingAssessmentGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: PROFILE_ROUTE,
    loadChildren: () => import('app/pages/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AngularFireAuthGuard, OngoingAssessmentGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: `${SKILLS_ROUTE}`,
    loadChildren: () => import('app/pages/skills/skills.module').then((m) => m.SkillsModule),
    canActivate: [AngularFireAuthGuard, OngoingAssessmentGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: `${ASSESSMENT_ROUTE}`,
    loadChildren: () => import('app/pages/assessment/assessment.module').then((m) => m.AssessmentModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: `${TRAINING_ROUTE}/:skillId`,
    loadChildren: () => import('app/pages/training/training.module').then((m) => m.TrainingModule),
    canActivate: [AngularFireAuthGuard, OngoingAssessmentGuard, TrainingGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: `${SUBJECT_ROUTE}/:subjectId`,
    loadChildren: () => import('app/pages/subject/subject.module').then((m) => m.SubjectModule),
    canActivate: [AngularFireAuthGuard, OngoingAssessmentGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  }
];

@NgModule({
  imports: [
    AuthenticationModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
