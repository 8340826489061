import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'edxp-has-finished-dialog',
  templateUrl: './has-finished-dialog.component.html',
  styleUrls: ['./has-finished-dialog.component.scss']
})
export class HasFinishedDialogComponent {
  constructor(private dialogRef: MatDialogRef<HasFinishedDialogComponent>) {}

  public goToSummary(): void {
    this.dialogRef.close(true);
  }

  public goBack(): void {
    this.dialogRef.close(false);
  }
}
