export interface TreeLikeStructure<T> {
  id: string;
  children: T[];
}

export const depthFirstSearch = <T extends TreeLikeStructure<T>>(searchId: string, firstSkillNode: T | undefined): T | undefined => {
  const stack = [];
  stack.push(firstSkillNode);
  while (stack.length > 0) {
    const skillNode = stack.pop();
    if (skillNode && skillNode.id === searchId) return skillNode;
    skillNode?.children.forEach((childSkillNode) => stack.push(childSkillNode));
  }

  return undefined;
};
