<div mat-dialog-content
  class="dialog-content">
  <div class="icon-container">
    <i class="ti ti-thumb-up"></i>
  </div>
  <h2 class="text-center">{{ 'ASSESSMENT.HAS-FINISHED.ALL-QUESTIONS' | translate }}</h2>
  <p class="text-center">{{ 'ASSESSMENT.HAS-FINISHED.NO-WORRY' | translate }}</p>
  <button mat-raised-button
    class="confirm-btn"
    (click)="goToSummary()">{{ 'ASSESSMENT.HAS-FINISHED.SUMMARY' | translate }} <i class="ti ti-arrow-right"></i></button>
  <button class="submit-assessment"
    mat-raised-button
    (click)="goBack()">
    <i class="ti ti-arrow-left"></i> {{ 'ASSESSMENT.HAS-FINISHED.BACK' | translate }}
  </button>
</div>
