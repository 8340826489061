import firebase from 'firebase/app';

export const flamelinkSchemaPrefix: string = '_fl_meta_.schema';
export const usersPrefix: string = 'users';
export const usersPhotosPrefix: string = 'profile-photos';

//FIREBASE
export type FirebaseUser = firebase.User;

//FIREBASE FIRESTORE
export type FirebaseFirestoreDocumentData = firebase.firestore.DocumentData;
export type FirebaseFirestoreDocumentReference = firebase.firestore.DocumentReference<FirebaseFirestoreDocumentData>;
export type FirestoreDocumentData = firebase.firestore.CollectionReference<FirebaseFirestoreDocumentData>;

// FIREBASE STORAGE
export type FirebaseStorageRef = firebase.storage.Reference;
export type FirebaseStorageUploadTaskSnapshot = firebase.storage.UploadTaskSnapshot;

// FIREBASE AUTH
export type FirebaseAuthUserCredential = firebase.auth.UserCredential;
export type FirebaseAuthActionCodeInfo = firebase.auth.ActionCodeInfo;
export type FirebaseAuthAuthCredential = firebase.auth.AuthCredential;
export type FirebaseAuthError = firebase.auth.Error;
