import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthErrorsLogin } from '@edxp-auth/utils/constants/auth-errors';
import { LOGIN_ROUTE } from '@edxp-core/utils/constants/routes.constants';
import { Router } from '@angular/router';
import { AuthService } from '@edxp-auth/services/auth.service';
import { FirebaseAuthError } from '@edxp-core/api/utils/firebase.utils';

@Component({
  selector: 'edxp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPassForm: FormGroup = new FormGroup({});

  public resetPassSuccess: boolean = false;

  public errorMsg: string = '';

  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService) {}

  public ngOnInit(): void {
    this.forgotPassForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  public sendResetLink(): void {
    this.resetPassSuccess = false;
    if (this.forgotPassForm.valid) {
      this.errorMsg = '';
      this.authService
        .sendResetPasswordLink(this.forgotPassForm?.controls.email.value)
        ?.then(() => {
          this.resetPassSuccess = true;
          this.forgotPassForm.reset();
        })
        .catch((err: FirebaseAuthError) => {
          switch (err.code) {
            case AuthErrorsLogin.USER_NOT_FOUND: {
              this.errorMsg = 'AUTHENTICATION.ERRORS.EMAIL.NOT-FOUND';
              break;
            }
            default: {
              this.errorMsg = 'AUTHENTICATION.ERRORS.UNKNOWN';
              break;
            }
          }
        });
    }
  }

  public goToLogin(): void {
    this.errorMsg = '';
    this.resetPassSuccess = false;
    this.forgotPassForm.reset();
    this.router.navigate([LOGIN_ROUTE]);
  }
}
