<div mat-dialog-content
  class="dialog-content">
  <h2 class="text-center">{{ 'ASSESSMENT.SUBMIT-FAILED.OUR-APOLOGIES' | translate }}</h2>
  <p class="text-center">{{ 'ASSESSMENT.SUBMIT-FAILED.TEXT' | translate }}</p>
  <button class="submit-assessment"
    mat-raised-button
    (click)="goBack()">
    <i class="ti ti-arrow-left"></i> {{ 'NOTIFICATIONS.DISMISS' | translate }}
  </button>
</div>
