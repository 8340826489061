import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SubscriptionCleanup implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.complete();
  }
}
