<ng-container *ngIf="(isDesktop$ | async) || isAllowedPage; else mobileView">
  <router-outlet></router-outlet>
  <!-- <edxp-page-under-maintenance></edxp-page-under-maintenance> -->
</ng-container>
<ng-template #mobileView>
  <div class="mobile-placeholder-page-wrapper">
    <div class="placeholder-text">
      <div class="icon-container">
        <img src="/assets/images/banner-visual.png" />
      </div>
      <span class="message">{{ 'MOBILE-PLACEHOLDER.MESSAGE' | translate }}</span>
      <span class="explanation">{{ 'MOBILE-PLACEHOLDER.EXPLANATION' | translate }}</span>
      <span class="go-back-link"
        (click)="goBack()">{{ 'MOBILE-PLACEHOLDER.BACK' | translate }}</span>
    </div>
  </div>
</ng-template>
