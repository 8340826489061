import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'edxp-submit-failed-dialog',
  templateUrl: './submit-failed-dialog.component.html',
  styleUrls: ['./submit-failed-dialog.component.scss']
})
export class SubmitFailedDialogComponent {
  constructor(private dialogRef: MatDialogRef<SubmitFailedDialogComponent>) {}

  public goToSummary(): void {
    this.dialogRef.close(true);
  }

  public goBack(): void {
    this.dialogRef.close(false);
  }
}
